:where(html) {
  --font-sans: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif;
  --font-serif: ui-serif, serif;
  --font-mono: Dank Mono, Operator Mono, Inconsolata, Fira Mono, ui-monospace, SF Mono, Monaco, Droid Sans Mono, Source Code Pro, monospace;
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;
  --font-weight-9: 900;
  --font-lineheight-00: .95;
  --font-lineheight-0: 1.1;
  --font-lineheight-1: 1.25;
  --font-lineheight-2: 1.375;
  --font-lineheight-3: 1.5;
  --font-lineheight-4: 1.75;
  --font-lineheight-5: 2;
  --font-letterspacing-0: -.05em;
  --font-letterspacing-1: .025em;
  --font-letterspacing-2: .05em;
  --font-letterspacing-3: .075em;
  --font-letterspacing-4: .15em;
  --font-letterspacing-5: .5em;
  --font-letterspacing-6: .75em;
  --font-letterspacing-7: 1em;
  --font-size-00: .5rem;
  --font-size-0: .75rem;
  --font-size-1: 1rem;
  --font-size-2: 1.1rem;
  --font-size-3: 1.25rem;
  --font-size-4: 1.5rem;
  --font-size-5: 2rem;
  --font-size-6: 2.5rem;
  --font-size-7: 3rem;
  --font-size-8: 3.5rem;
  --font-size-fluid-0: clamp(.75rem, 2vw, 1rem);
  --font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
  --font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
  --font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);
  --size-000: -.5rem;
  --size-00: -.25rem;
  --size-1: .25rem;
  --size-2: .5rem;
  --size-3: 1rem;
  --size-4: 1.25rem;
  --size-5: 1.5rem;
  --size-6: 1.75rem;
  --size-7: 2rem;
  --size-8: 3rem;
  --size-9: 4rem;
  --size-10: 5rem;
  --size-11: 7.5rem;
  --size-12: 10rem;
  --size-13: 15rem;
  --size-14: 20rem;
  --size-15: 30rem;
  --size-fluid-1: clamp(.5rem, 1vw, 1rem);
  --size-fluid-2: clamp(1rem, 2vw, 1.5rem);
  --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
  --size-fluid-4: clamp(2rem, 4vw, 3rem);
  --size-fluid-5: clamp(4rem, 5vw, 5rem);
  --size-fluid-6: clamp(5rem, 7vw, 7.5rem);
  --size-fluid-7: clamp(7.5rem, 10vw, 10rem);
  --size-fluid-8: clamp(10rem, 20vw, 15rem);
  --size-fluid-9: clamp(15rem, 30vw, 20rem);
  --size-fluid-10: clamp(20rem, 40vw, 30rem);
  --size-content-1: 20ch;
  --size-content-2: 45ch;
  --size-content-3: 60ch;
  --size-header-1: 20ch;
  --size-header-2: 25ch;
  --size-header-3: 35ch;
  --size-xxs: 240px;
  --size-xs: 360px;
  --size-sm: 480px;
  --size-md: 768px;
  --size-lg: 1024px;
  --size-xl: 1440px;
  --size-xxl: 1920px;
  --ease-1: cubic-bezier(.25, 0, .5, 1);
  --ease-2: cubic-bezier(.25, 0, .4, 1);
  --ease-3: cubic-bezier(.25, 0, .3, 1);
  --ease-4: cubic-bezier(.25, 0, .2, 1);
  --ease-5: cubic-bezier(.25, 0, .1, 1);
  --ease-in-1: cubic-bezier(.25, 0, 1, 1);
  --ease-in-2: cubic-bezier(.5, 0, 1, 1);
  --ease-in-3: cubic-bezier(.7, 0, 1, 1);
  --ease-in-4: cubic-bezier(.9, 0, 1, 1);
  --ease-in-5: cubic-bezier(1, 0, 1, 1);
  --ease-out-1: cubic-bezier(0, 0, .75, 1);
  --ease-out-2: cubic-bezier(0, 0, .5, 1);
  --ease-out-3: cubic-bezier(0, 0, .3, 1);
  --ease-out-4: cubic-bezier(0, 0, .1, 1);
  --ease-out-5: cubic-bezier(0, 0, 0, 1);
  --ease-in-out-1: cubic-bezier(.1, 0, .9, 1);
  --ease-in-out-2: cubic-bezier(.3, 0, .7, 1);
  --ease-in-out-3: cubic-bezier(.5, 0, .5, 1);
  --ease-in-out-4: cubic-bezier(.7, 0, .3, 1);
  --ease-in-out-5: cubic-bezier(.9, 0, .1, 1);
  --ease-elastic-1: cubic-bezier(.5, .75, .75, 1.25);
  --ease-elastic-2: cubic-bezier(.5, 1, .75, 1.25);
  --ease-elastic-3: cubic-bezier(.5, 1.25, .75, 1.25);
  --ease-elastic-4: cubic-bezier(.5, 1.5, .75, 1.25);
  --ease-elastic-5: cubic-bezier(.5, 1.75, .75, 1.25);
  --ease-squish-1: cubic-bezier(.5, -.1, .1, 1.5);
  --ease-squish-2: cubic-bezier(.5, -.3, .1, 1.5);
  --ease-squish-3: cubic-bezier(.5, -.5, .1, 1.5);
  --ease-squish-4: cubic-bezier(.5, -.7, .1, 1.5);
  --ease-squish-5: cubic-bezier(.5, -.9, .1, 1.5);
  --ease-step-1: steps(2);
  --ease-step-2: steps(3);
  --ease-step-3: steps(4);
  --ease-step-4: steps(7);
  --ease-step-5: steps(10);
  --layer-1: 1;
  --layer-2: 2;
  --layer-3: 3;
  --layer-4: 4;
  --layer-5: 5;
  --layer-important: 2147480000;
  --shadow-color: 220 3% 15%;
  --shadow-strength: 1%;
  --shadow-1: 0 1px 2px -1px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 9%) );
  --shadow-2: 0 3px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 7px 14px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 5%) );
  --shadow-3: 0 -1px 3px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 2%) ), 0 1px 2px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 2%) ), 0 2px 5px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 4%) ), 0 4px 12px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 5%) ), 0 12px 15px -5px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 7%) );
  --shadow-4: 0 -2px 5px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 2%) ), 0 1px 1px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 2px 2px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 5px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 4%) ), 0 9px 9px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 5%) ), 0 16px 16px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 6%) );
  --shadow-5: 0 -1px 2px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 2%) ), 0 2px 1px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 5px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 10px 10px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 4%) ), 0 20px 20px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 5%) ), 0 40px 40px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 7%) );
  --shadow-6: 0 -1px 2px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 2%) ), 0 3px 2px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 7px 5px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 3%) ), 0 12px 10px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 4%) ), 0 22px 18px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 5%) ), 0 41px 33px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 6%) ), 0 100px 80px -2px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 7%) );
  --inner-shadow-0: inset 0 0 0 1px hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 9%) );
  --inner-shadow-1: inset 0 1px 2px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 9%) );
  --inner-shadow-2: inset 0 1px 4px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 9%) );
  --inner-shadow-3: inset 0 2px 8px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 9%) );
  --inner-shadow-4: inset 0 2px 14px 0 hsl(var(--shadow-color) / calc(var(--shadow-strength)  + 9%) );
  --ratio-square: 1;
  --ratio-landscape: 4 / 3;
  --ratio-portrait: 3 / 4;
  --ratio-widescreen: 16 / 9;
  --ratio-ultrawide: 18 / 5;
  --ratio-golden: 1.618 / 1;
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --red-0: #fff5f5;
  --red-1: #ffe3e3;
  --red-2: #ffc9c9;
  --red-3: #ffa8a8;
  --red-4: #ff8787;
  --red-5: #ff6b6b;
  --red-6: #fa5252;
  --red-7: #f03e3e;
  --red-8: #e03131;
  --red-9: #c92a2a;
  --pink-0: #fff0f6;
  --pink-1: #ffdeeb;
  --pink-2: #fcc2d7;
  --pink-3: #faa2c1;
  --pink-4: #f783ac;
  --pink-5: #f06595;
  --pink-6: #e64980;
  --pink-7: #d6336c;
  --pink-8: #c2255c;
  --pink-9: #a61e4d;
  --grape-0: #f8f0fc;
  --grape-1: #f3d9fa;
  --grape-2: #eebefa;
  --grape-3: #e599f7;
  --grape-4: #da77f2;
  --grape-5: #cc5de8;
  --grape-6: #be4bdb;
  --grape-7: #ae3ec9;
  --grape-8: #9c36b5;
  --grape-9: #862e9c;
  --violet-0: #f3f0ff;
  --violet-1: #e5dbff;
  --violet-2: #d0bfff;
  --violet-3: #b197fc;
  --violet-4: #9775fa;
  --violet-5: #845ef7;
  --violet-6: #7950f2;
  --violet-7: #7048e8;
  --violet-8: #6741d9;
  --violet-9: #5f3dc4;
  --indigo-0: #edf2ff;
  --indigo-1: #dbe4ff;
  --indigo-2: #bac8ff;
  --indigo-3: #91a7ff;
  --indigo-4: #748ffc;
  --indigo-5: #5c7cfa;
  --indigo-6: #4c6ef5;
  --indigo-7: #4263eb;
  --indigo-8: #3b5bdb;
  --indigo-9: #364fc7;
  --blue-0: #e7f5ff;
  --blue-1: #d0ebff;
  --blue-2: #a5d8ff;
  --blue-3: #74c0fc;
  --blue-4: #4dabf7;
  --blue-5: #339af0;
  --blue-6: #228be6;
  --blue-7: #1c7ed6;
  --blue-8: #1971c2;
  --blue-9: #1864ab;
  --cyan-0: #e3fafc;
  --cyan-1: #c5f6fa;
  --cyan-2: #99e9f2;
  --cyan-3: #66d9e8;
  --cyan-4: #3bc9db;
  --cyan-5: #22b8cf;
  --cyan-6: #15aabf;
  --cyan-7: #1098ad;
  --cyan-8: #0c8599;
  --cyan-9: #0b7285;
  --teal-0: #e6fcf5;
  --teal-1: #c3fae8;
  --teal-2: #96f2d7;
  --teal-3: #63e6be;
  --teal-4: #38d9a9;
  --teal-5: #20c997;
  --teal-6: #12b886;
  --teal-7: #0ca678;
  --teal-8: #099268;
  --teal-9: #087f5b;
  --green-0: #ebfbee;
  --green-1: #d3f9d8;
  --green-2: #b2f2bb;
  --green-3: #8ce99a;
  --green-4: #69db7c;
  --green-5: #51cf66;
  --green-6: #40c057;
  --green-7: #37b24d;
  --green-8: #2f9e44;
  --green-9: #2b8a3e;
  --lime-0: #f4fce3;
  --lime-1: #e9fac8;
  --lime-2: #d8f5a2;
  --lime-3: #c0eb75;
  --lime-4: #a9e34b;
  --lime-5: #94d82d;
  --lime-6: #82c91e;
  --lime-7: #74b816;
  --lime-8: #66a80f;
  --lime-9: #5c940d;
  --yellow-0: #fff9db;
  --yellow-1: #fff3bf;
  --yellow-2: #ffec99;
  --yellow-3: #ffe066;
  --yellow-4: #ffd43b;
  --yellow-5: #fcc419;
  --yellow-6: #fab005;
  --yellow-7: #f59f00;
  --yellow-8: #f08c00;
  --yellow-9: #e67700;
  --orange-0: #fff4e6;
  --orange-1: #ffe8cc;
  --orange-2: #ffd8a8;
  --orange-3: #ffc078;
  --orange-4: #ffa94d;
  --orange-5: #ff922b;
  --orange-6: #fd7e14;
  --orange-7: #f76707;
  --orange-8: #e8590c;
  --orange-9: #d9480f;
  --gradient-1: linear-gradient(to bottom right, #1f005c, #5b0060, #870160, #ac255e, #ca485c, #e16b5c, #f39060, #ffb56b);
  --gradient-2: linear-gradient(to bottom right, #48005c, #8300e2, #a269ff);
  --gradient-3: radial-gradient(circle at top right, #0ff, #0ff0), radial-gradient(circle at bottom left, #ff1492, #ff149200);
  --gradient-4: linear-gradient(to bottom right, #00f5a0, #00d9f5);
  --gradient-5: conic-gradient(from -270deg at 75% 110%, #f0f, #fffaf0);
  --gradient-6: conic-gradient(from -90deg at top left, #000, #fff);
  --gradient-7: linear-gradient(to bottom right, #72c6ef, #004e8f);
  --gradient-8: conic-gradient(from 90deg at 50% 0%, #111, 50%, #222, #111);
  --gradient-9: conic-gradient(from .5turn at bottom center, #add8e6, #fff);
  --gradient-10: conic-gradient(from 90deg at 40% -25%, gold, #f79d03, #ee6907, #e6390a, #de0d0d, #d61039, #cf1261, #c71585, #cf1261, #d61039, #de0d0d, #ee6907, #f79d03, gold, gold, gold);
  --gradient-11: conic-gradient(at bottom left, #ff1493, cyan);
  --gradient-12: conic-gradient(from 90deg at 25% -10%, #ff4500, #d3f340, #7bee85, #afeeee, #7bee85);
  --gradient-13: radial-gradient(circle at 50% 200%, #000142, #3b0083, #b300c3, #ff059f, #ff4661, #ffad86, #fff3c7);
  --gradient-14: conic-gradient(at top right, lime, cyan);
  --gradient-15: linear-gradient(to bottom right, #c7d2fe, #fecaca, #fef3c7);
  --gradient-16: radial-gradient(circle at 50% -250%, #374151, #111827, #000);
  --gradient-17: conic-gradient(from -90deg at 50% -25%, blue, #8a2be2);
  --gradient-18: linear-gradient(0deg, #f00c, #f000 75%), linear-gradient(60deg, #ff0c, #ff00 75%), linear-gradient(120deg, #0f0c, #0f00 75%), linear-gradient(180deg, #0ffc, #0ff0 75%), linear-gradient(240deg, #00fc, #00f0 75%), linear-gradient(300deg, #f0fc, #f0f0 75%);
  --gradient-19: linear-gradient(to bottom right, #ffe259, #ffa751);
  --gradient-20: conic-gradient(from -135deg at -10% center, orange, #ff7715, #ff522a, #ff3f47, #ff5482, #ff69b4);
  --gradient-21: conic-gradient(from -90deg at 25% 115%, red, #f06, #f0c, #c0f, #60f, #00f, #00f, #00f, #00f);
  --gradient-22: linear-gradient(to bottom right, #acb6e5, #86fde8);
  --gradient-23: linear-gradient(to bottom right, #536976, #292e49);
  --gradient-24: conic-gradient(from .5turn at 0% 0%, #00c476, 10%, #82b0ff, 90%, #00c476);
  --gradient-25: conic-gradient(at 125% 50%, #b78cf7, #ff7c94, #ffcf0d, #ff7c94, #b78cf7);
  --gradient-26: linear-gradient(to bottom right, #9796f0, #fbc7d4);
  --gradient-27: conic-gradient(from .5turn at bottom left, #ff1493, #639);
  --gradient-28: conic-gradient(from -90deg at 50% 105%, #fff, orchid);
  --gradient-29: radial-gradient(circle at top right, #bfb3ff, #bfb3ff00), radial-gradient(circle at bottom left, #86acf9, #86acf900);
  --gradient-30: radial-gradient(circle at top right, #00ff80, #00ff8000), radial-gradient(circle at bottom left, #adffd6, #adffd600);
  --noise-1: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.005' numOctaves='2' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  --noise-2: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 300 300' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.05' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  --noise-3: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.25' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  --noise-4: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 2056 2056' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.5' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  --noise-5: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 2056 2056' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='a'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='.75' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23a)'/%3E%3C/svg%3E");
  --noise-filter-1: contrast(300%) brightness(100%);
  --noise-filter-2: contrast(200%) brightness(150%);
  --noise-filter-3: contrast(200%) brightness(250%);
  --noise-filter-4: contrast(200%) brightness(500%);
  --noise-filter-5: contrast(200%) brightness(1000%);
  --animation-fade-in: fade-in .5s var(--ease-3);
  --animation-fade-in-bloom: fade-in-bloom 2s var(--ease-3);
  --animation-fade-out: fade-out .5s var(--ease-3);
  --animation-fade-out-bloom: fade-out-bloom 2s var(--ease-3);
  --animation-scale-up: scale-up .5s var(--ease-3);
  --animation-scale-down: scale-down .5s var(--ease-3);
  --animation-slide-out-up: slide-out-up .5s var(--ease-3);
  --animation-slide-out-down: slide-out-down .5s var(--ease-3);
  --animation-slide-out-right: slide-out-right .5s var(--ease-3);
  --animation-slide-out-left: slide-out-left .5s var(--ease-3);
  --animation-slide-in-up: slide-in-up .5s var(--ease-3);
  --animation-slide-in-down: slide-in-down .5s var(--ease-3);
  --animation-slide-in-right: slide-in-right .5s var(--ease-3);
  --animation-slide-in-left: slide-in-left .5s var(--ease-3);
  --animation-shake-x: shake-x .75s var(--ease-out-5);
  --animation-shake-y: shake-y .75s var(--ease-out-5);
  --animation-spin: spin 2s linear infinite;
  --animation-ping: ping 5s var(--ease-out-3) infinite;
  --animation-blink: blink 1s var(--ease-out-3) infinite;
  --animation-float: float 3s var(--ease-in-out-3) infinite;
  --animation-bounce: bounce 2s var(--ease-squish-2) infinite;
  --animation-pulse: pulse 2s var(--ease-out-3) infinite;
  --border-size-1: 1px;
  --border-size-2: 2px;
  --border-size-3: 5px;
  --border-size-4: 10px;
  --border-size-5: 25px;
  --radius-1: 2px;
  --radius-2: 5px;
  --radius-3: 1rem;
  --radius-4: 2rem;
  --radius-5: 4rem;
  --radius-6: 8rem;
  --radius-round: 100000px;
  --radius-blob-1: 30% 70% 70% 30% / 53% 30% 70% 47%;
  --radius-blob-2: 53% 47% 34% 66% / 63% 46% 54% 37%;
  --radius-blob-3: 37% 63% 56% 44% / 49% 56% 44% 51%;
  --radius-blob-4: 63% 37% 37% 63% / 43% 37% 63% 57%;
  --radius-blob-5: 49% 51% 48% 52% / 57% 44% 56% 43%;
  --radius-conditional-1: clamp(0px, calc(100vw - 100%) * 100000, var(--radius-1));
  --radius-conditional-2: clamp(0px, calc(100vw - 100%) * 100000, var(--radius-2));
  --radius-conditional-3: clamp(0px, calc(100vw - 100%) * 100000, var(--radius-3));
  --radius-conditional-4: clamp(0px, calc(100vw - 100%) * 100000, var(--radius-4));
  --radius-conditional-5: clamp(0px, calc(100vw - 100%) * 100000, var(--radius-5));
  --radius-conditional-6: clamp(0px, calc(100vw - 100%) * 100000, var(--radius-6));
}

@media (prefers-color-scheme: dark) {
  :where(html) {
    --shadow-color: 220 40% 2%;
    --shadow-strength: 25%;
  }
}

@keyframes fade-in {
  to {
    opacity: 1;
  }
}

@keyframes fade-in-bloom {
  0% {
    filter: brightness() blur(20px);
    opacity: 0;
  }

  10% {
    filter: brightness(2) blur(10px);
    filter: brightness(.5) blur(10px);
    opacity: 1;
  }

  to {
    filter: brightness() blur();
    opacity: 1;
  }
}

@keyframes fade-out {
  to {
    opacity: 0;
  }
}

@keyframes fade-out-bloom {
  to {
    filter: brightness() blur(20px);
    opacity: 0;
  }

  10% {
    filter: brightness(2) blur(10px);
    filter: brightness(.5) blur(10px);
    opacity: 1;
  }

  0% {
    filter: brightness() blur();
    opacity: 1;
  }
}

@keyframes scale-up {
  to {
    transform: scale(1.25);
  }
}

@keyframes scale-down {
  to {
    transform: scale(.75);
  }
}

@keyframes slide-out-up {
  to {
    transform: translateY(-100%);
  }
}

@keyframes slide-out-down {
  to {
    transform: translateY(100%);
  }
}

@keyframes slide-out-right {
  to {
    transform: translateX(100%);
  }
}

@keyframes slide-out-left {
  to {
    transform: translateX(-100%);
  }
}

@keyframes slide-in-up {
  0% {
    transform: translateY(100%);
  }
}

@keyframes slide-in-down {
  0% {
    transform: translateY(-100%);
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(-100%);
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(100%);
  }
}

@keyframes shake-x {
  0%, to {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-5%);
  }

  40% {
    transform: translateX(5%);
  }

  60% {
    transform: translateX(-5%);
  }

  80% {
    transform: translateX(5%);
  }
}

@keyframes shake-y {
  0%, to {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-5%);
  }

  40% {
    transform: translateY(5%);
  }

  60% {
    transform: translateY(-5%);
  }

  80% {
    transform: translateY(5%);
  }
}

@keyframes spin {
  to {
    transform: rotate(1turn);
  }
}

@keyframes ping {
  90%, to {
    opacity: 0;
    transform: scale(2);
  }
}

@keyframes blink {
  0%, to {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

@keyframes float {
  50% {
    transform: translateY(-25%);
  }
}

@keyframes bounce {
  25% {
    transform: translateY(-20%);
  }

  40% {
    transform: translateY(-3%);
  }

  0%, 60%, to {
    transform: translateY(0);
  }
}

@keyframes pulse {
  50% {
    transform: scale(.9);
  }
}

:where(html) {
  --link: var(--indigo-7);
  --link-visited: var(--grape-7);
  --text-1: var(--gray-9);
  --text-2: var(--gray-7);
  --surface-1: var(--gray-0);
  --surface-2: var(--gray-2);
  --surface-3: var(--gray-3);
  --surface-4: var(--gray-4);
  --scrollthumb-color: var(--gray-6);
  -webkit-text-size-adjust: none;
  accent-color: var(--link);
  background-color: var(--surface-1);
  block-size: 100%;
  caret-color: var(--link);
  color: var(--text-2);
  color-scheme: light;
  font-family: var(--font-sans);
  line-height: var(--font-lineheight-3);
  scrollbar-color: var(--scrollthumb-color) transparent;
}

@media (dynamic-range: high) {
  @supports (color(display-p3 0 .5 1)) {
    :where(html) {
      --link: #0085f8;
      --link-visited: #a436ff;
    }

    @supports (color: color(display-p3 0 0 0)) {
      :where(html) {
        --link: color(display-p3 0 .5 1);
        --link-visited: color(display-p3 .6 .2 1);
      }
    }
  }
}

@media (prefers-color-scheme: dark) {
  :where(html) {
    --link: var(--indigo-3);
    --link-visited: var(--grape-3);
    --text-1: var(--gray-1);
    --text-2: var(--gray-4);
    --surface-1: var(--gray-9);
    --surface-2: var(--gray-8);
    --surface-3: var(--gray-7);
    --surface-4: var(--gray-6);
    color-scheme: dark;
  }
}

:where(h1, h2, h3, h4, h5, h6, dt) {
  color: var(--text-1);
}

:where(a[href]) {
  color: var(--link);
}

:where(a[href]):visited {
  color: var(--link-visited);
}

:focus-visible {
  outline-color: var(--link);
}

@media (prefers-color-scheme: light) {
  :where(html) {
    --scrollthumb-color: var(--gray-7);
  }
}

*, :after, :before {
  box-sizing: border-box;
}

:where(:not(dialog)) {
  margin: 0;
}

:where(:not(fieldset, progress, meter)) {
  background-repeat: no-repeat;
  background-origin: border-box;
  border-style: solid;
  border-width: 0;
}

@media (prefers-reduced-motion: no-preference) {
  :where(html) {
    scroll-behavior: smooth;
  }

  :where(:focus-visible) {
    transition: outline-offset 145ms var(--ease-2);
  }

  :where(:not(:active):focus-visible) {
    transition-duration: .25s;
  }
}

:where(:not(:active):focus-visible) {
  outline-offset: 5px;
}

:where(body) {
  min-block-size: 100%;
}

:where(h1, h2, h3, h4, h5, h6) {
  font-weight: var(--font-weight-9);
  line-height: var(--font-lineheight-1);
}

:where(h1) {
  font-size: var(--font-size-8);
  max-inline-size: var(--size-header-1);
}

:where(h2) {
  font-size: var(--font-size-6);
  max-inline-size: var(--size-header-2);
}

:where(h3) {
  font-size: var(--font-size-5);
}

:where(h4) {
  font-size: var(--font-size-4);
}

:where(h5) {
  font-size: var(--font-size-3);
}

:where(h3, h4, h5, h6, dt) {
  max-inline-size: var(--size-header-3);
}

:where(p, ul, ol, dl, h6) {
  font-size: var(--font-size-2);
}

:where(a, u, ins, abbr) {
  text-underline-offset: 1px;
}

@supports (-moz-appearance:none) {
  :where(a, u, ins, abbr) {
    text-underline-offset: 2px;
  }
}

:where(a[href], area, button, input, label[for], select, summary, textarea, [tabindex]:not([tabindex*="-"])) {
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  touch-action: manipulation;
}

:where(a) {
  margin-block: calc(var(--size-1) * -1);
  margin-inline: calc(var(--size-1) * -1);
  padding-block: var(--size-1);
  padding-inline: var(--size-1);
}

:where(a):where([href]) {
  text-decoration-color: var(--indigo-2);
}

:where(a):where([href]):where(:visited) {
  text-decoration-color: var(--grape-2);
}

:where(a):where(:not(:hover)) {
  text-decoration: inherit;
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}

:where(img, svg, video) {
  block-size: auto;
  max-inline-size: 100%;
}

:where(input, button, textarea, select), :where(input[type="file"])::-webkit-file-upload-button {
  color: inherit;
  font: inherit;
  font-size: inherit;
  letter-spacing: inherit;
}

:where(input, textarea) {
  padding-block: var(--size-1);
  padding-inline: var(--size-2);
}

:where(select) {
  padding-block: .75ch;
  padding-inline: 1.25ch 0;
}

:where(textarea, select, input:not(button, button[type], input[type="button"], input[type="submit"], input[type="reset"])) {
  background-color: var(--surface-2);
  border-radius: var(--radius-2);
}

@media (prefers-color-scheme: dark) {
  :where(textarea, select, input:not(button, button[type], input[type="button"], input[type="submit"], input[type="reset"])) {
    background-color: #171a1c;
  }
}

:where(textarea) {
  resize: block;
}

:where(input[type="checkbox"], input[type="radio"]) {
  block-size: var(--size-3);
  inline-size: var(--size-3);
}

:where(svg) {
  stroke: none;
  fill: currentColor;
}

:where(svg):where(:not([fill])) {
  stroke: none;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}

:where(svg):where(:not([width])) {
  inline-size: var(--size-10);
}

:where(code, kbd, samp, pre) {
  font-family: var(--font-mono);
}

:where(:not(pre) > code, kbd) {
  white-space: nowrap;
}

:where(pre) {
  max-inline-size: max-content;
  min-inline-size: 0;
  white-space: pre;
}

:where(:not(pre) > code) {
  background: var(--surface-2);
  border-radius: var(--radius-2);
  padding: var(--size-1) var(--size-2);
}

:where(kbd, var) {
  border-color: var(--surface-4);
  border-radius: var(--radius-2);
  border-width: var(--border-size-1);
  padding: var(--size-1) var(--size-2);
}

:where(mark) {
  border-radius: var(--radius-2);
  padding-inline: var(--size-1);
}

:where(ol, ul) {
  padding-inline-start: var(--size-8);
}

:where(li) {
  padding-inline-start: var(--size-2);
}

:where(li, dd, figcaption) {
  max-inline-size: var(--size-content-2);
}

:where(p) {
  max-inline-size: var(--size-content-3);
}

:where(dt, summary) {
  font-weight: var(--font-weight-7);
}

:where(dt:not(:first-of-type)) {
  margin-block-start: var(--size-5);
}

:where(small) {
  font-size: max(.5em, var(--font-size-0));
  max-inline-size: var(--size-content-1);
}

:where(hr) {
  background-color: var(--surface-3);
  height: var(--border-size-2);
  margin-block: var(--size-fluid-5);
}

:where(figure) {
  gap: var(--size-2);
  place-items: center;
  display: grid;
}

:where(figure) > :where(figcaption) {
  font-size: var(--font-size-1);
}

:where(blockquote, :not(blockquote) > cite) {
  border-inline-start-width: var(--border-size-3);
}

:where(blockquote) {
  gap: var(--size-3);
  max-inline-size: var(--size-content-2);
  padding-block: var(--size-3);
  padding-inline: var(--size-4);
  display: grid;
}

:where(:not(blockquote) > cite) {
  padding-inline-start: var(--size-2);
}

:where(summary) {
  background: var(--surface-3);
  border-radius: var(--radius-2);
  margin: calc(var(--size-2) * -1) calc(var(--size-3) * -1);
  padding: var(--size-2) var(--size-3);
}

:where(details) {
  background: var(--surface-2);
  border-radius: var(--radius-2);
  padding-block: var(--size-2);
  padding-inline: var(--size-3);
}

:where(details[open] > summary) {
  margin-bottom: var(--size-2);
  border-end-start-radius: 0;
  border-end-end-radius: 0;
}

:where(fieldset) {
  border: var(--border-size-1) solid var(--surface-4);
  border-radius: var(--radius-2);
}

:where(del) {
  background: var(--red-9);
  color: var(--red-2);
}

:where(ins) {
  background: var(--green-9);
  color: var(--green-1);
}

:where(abbr) {
  text-decoration-color: var(--blue-5);
}

:where(dialog) {
  background-color: var(--surface-1);
  border-radius: var(--radius-3);
  box-shadow: var(--shadow-6);
  color: inherit;
}

@media (prefers-color-scheme: dark) {
  :where(dialog) {
    background-color: var(--surface-2);
  }
}

:where(dialog)::backdrop {
  backdrop-filter: blur(25px);
}

:where(html[\:has\(dialog\[open\]\)]) {
  overflow: hidden;
}

:where(html:has(dialog[open])) {
  overflow: hidden;
}

:where(menu) {
  gap: var(--size-3);
  padding-inline-start: 0;
  display: flex;
}

:where(sup) {
  font-size: .5em;
}

@font-face {
  font-family: remixicon;
  src: url("remixicon.d14ab26c.eot");
  src: url("remixicon.d14ab26c.eot#iefix") format("embedded-opentype"), url("remixicon.98f3713e.woff2") format("woff2"), url("remixicon.3b906fe4.woff") format("woff"), url("remixicon.c8399d45.ttf") format("truetype"), url("remixicon.a0b983c4.svg#remixicon") format("svg");
  font-display: swap;
}

[class^="ri-"], [class*="ri-"] {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-family: remixicon !important;
}

.ri-lg {
  vertical-align: -.0667em;
  font-size: 1.3333em;
  line-height: .75em;
}

.ri-xl {
  vertical-align: -.075em;
  font-size: 1.5em;
  line-height: .6666em;
}

.ri-xxs {
  font-size: .5em;
}

.ri-xs {
  font-size: .75em;
}

.ri-sm {
  font-size: .875em;
}

.ri-1x {
  font-size: 1em;
}

.ri-2x {
  font-size: 2em;
}

.ri-3x {
  font-size: 3em;
}

.ri-4x {
  font-size: 4em;
}

.ri-5x {
  font-size: 5em;
}

.ri-6x {
  font-size: 6em;
}

.ri-7x {
  font-size: 7em;
}

.ri-8x {
  font-size: 8em;
}

.ri-9x {
  font-size: 9em;
}

.ri-10x {
  font-size: 10em;
}

.ri-fw {
  text-align: center;
  width: 1.25em;
}

.ri-chat-quote-line:before {
  content: "";
}

.ri-arrow-up-s-line:before {
  content: "";
}

.ri-double-quotes-l:before {
  content: "";
}

.ri-double-quotes-r:before {
  content: "";
}

.splide__track--nav > .splide__list > .splide__slide {
  cursor: pointer;
  opacity: .7;
  border: 3px solid #0000;
  border-radius: 4px;
}

.splide__track--nav > .splide__list > .splide__slide.is-active {
  opacity: 1;
  border: 3px solid #20b2aa;
}

.splide__arrows--ttb .splide__arrow {
  left: 50%;
  transform: translate(-50%);
}

.splide__arrows--ttb .splide__arrow--prev {
  top: 1em;
}

.splide__arrows--ttb .splide__arrow--prev svg {
  transform: rotate(-90deg);
}

.splide__arrows--ttb .splide__arrow--next {
  top: auto;
  bottom: 1em;
}

.splide__arrows--ttb .splide__arrow--next svg {
  transform: rotate(90deg);
}

.splide__pagination--ttb {
  flex-direction: column;
  padding: 1em 0;
  display: flex;
  inset: 0 1em 0 auto;
}

.splide__pagination--ttb .splide__pagination__page {
  height: 20px;
  width: 5px;
}

.splide__arrow {
  cursor: pointer;
  height: 2.5em;
  width: 2.5em;
  z-index: 1;
  background: none;
  border: 0;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.splide__arrow svg {
  fill: #20b2aa;
  height: 2.5em;
  width: 2.5em;
  transition: fill .2s linear;
}

.splide__arrow:hover:not(:disabled) svg {
  fill: #57e1d9;
}

.splide__arrow:disabled {
  opacity: .3;
}

.splide__arrow:focus-visible {
  outline-offset: 3px;
  outline: 3px solid #20b2aa;
}

.splide__arrow--prev {
  left: 1em;
}

.splide__arrow--prev svg {
  transform: scaleX(-1);
}

.splide__arrow--next {
  right: 1em;
}

.splide.is-focus-in .splide__arrow:focus {
  outline-offset: 3px;
  outline: 3px solid #20b2aa;
}

.splide__pagination {
  z-index: 1;
  padding: 0 1em;
  position: absolute;
  bottom: 1em;
  left: 0;
  right: 0;
}

.splide__pagination__page {
  height: 12px;
  width: 24px;
  background: #ccc;
  border: 0;
  border-radius: 4px;
  margin: 3px;
  padding: 0;
  transition: background-color .2s linear;
  display: inline-block;
  position: relative;
}

.splide__pagination__page.is-active {
  z-index: 1;
  background: #20b2aa;
}

.splide__pagination__page:hover {
  cursor: pointer;
  opacity: .9;
  background: #57e1d9;
}

.splide__pagination__page:focus-visible, .splide.is-focus-in .splide__pagination__page:focus {
  outline-offset: 3px;
  outline: 3px solid #20b2aa;
}

.splide__slide {
  -webkit-tap-highlight-color: #0000;
  border-radius: 4px;
}

.splide__slide:focus {
  outline: 0;
}

@supports (outline-offset:-3px) {
  .splide__slide:focus-visible {
    outline-offset: -3px;
    outline: 3px solid #20b2aa;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide__slide:focus-visible {
    border: 3px solid #20b2aa;
  }
}

@supports (outline-offset:-3px) {
  .splide.is-focus-in .splide__slide:focus {
    outline-offset: -3px;
    outline: 3px solid #20b2aa;
  }
}

@media screen and (-ms-high-contrast: none) {
  .splide.is-focus-in .splide__slide:focus {
    border: 3px solid #20b2aa;
  }

  .splide.is-focus-in .splide__track > .splide__list > .splide__slide:focus {
    border-color: #20b2aa;
  }
}

.splide__container {
  box-sizing: border-box;
  position: relative;
}

.splide__list {
  backface-visibility: hidden;
  height: 100%;
  display: flex;
  margin: 0 !important;
  padding: 0 !important;
}

.splide.is-initialized:not(.is-active) .splide__list {
  display: block;
}

.splide__pagination {
  pointer-events: none;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  display: flex;
}

.splide__pagination li {
  pointer-events: auto;
  margin: 0;
  line-height: 1;
  list-style-type: none;
  display: inline-block;
}

.splide:not(.is-overflow) .splide__pagination {
  display: none;
}

.splide__progress__bar {
  width: 0;
}

.splide {
  visibility: hidden;
  position: relative;
}

.splide.is-initialized, .splide.is-rendered {
  visibility: visible;
}

.splide__slide {
  backface-visibility: hidden;
  box-sizing: border-box;
  flex-shrink: 0;
  margin: 0;
  position: relative;
  list-style-type: none !important;
}

.splide__slide img {
  vertical-align: bottom;
}

.splide__spinner {
  contain: strict;
  height: 20px;
  width: 20px;
  border: 2px solid #20b2aa;
  border-left-color: #0000;
  border-radius: 50%;
  margin: auto;
  animation: splide-loading 1s linear infinite;
  display: inline-block;
  position: absolute;
  inset: 0;
}

.splide__sr {
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  border: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.splide__toggle.is-active .splide__toggle__play, .splide__toggle__pause {
  display: none;
}

.splide__toggle.is-active .splide__toggle__pause {
  display: inline;
}

.splide__track {
  z-index: 0;
  position: relative;
  overflow: hidden;
}

@keyframes splide-loading {
  0% {
    transform: rotate(0);
  }

  to {
    transform: rotate(1turn);
  }
}

.splide__track--draggable {
  -webkit-touch-callout: none;
  user-select: none;
}

.splide__track--fade > .splide__list > .splide__slide {
  opacity: 0;
  z-index: 0;
  margin: 0 !important;
}

.splide__track--fade > .splide__list > .splide__slide.is-active {
  opacity: 1;
  z-index: 1;
}

.splide--rtl {
  direction: rtl;
}

.splide__track--ttb > .splide__list {
  display: block;
}

.splide__progress__bar {
  height: 3px;
  background: #ccc;
}

.splide__toggle {
  cursor: pointer;
}

.splide__toggle:focus-visible, .splide.is-focus-in .splide__toggle:focus {
  outline-offset: 3px;
  outline: 3px solid #20b2aa;
}

.splide__arrows--rtl .splide__arrow--prev {
  left: auto;
  right: 1em;
}

.splide__arrows--rtl .splide__arrow--prev svg {
  transform: scaleX(1);
}

.splide__arrows--rtl .splide__arrow--next {
  left: 1em;
  right: auto;
}

.splide__arrows--rtl .splide__arrow--next svg {
  transform: scaleX(-1);
}

.splide__slider > .splide__arrows .splide__arrow--prev {
  left: -2.5em;
}

.splide__slider > .splide__arrows .splide__arrow--next {
  right: -2.5em;
}

.splide {
  padding: 3em;
}

@font-face {
  font-family: NoirPro-Bold;
  font-style: normal;
  src: local(NoirPro-Bold), url("NoirPro-Bold.4e60b17e.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: NoirPro-BoldItalic;
  font-style: normal;
  src: local(NoirPro-BoldItalic), url("NoirPro-BoldItalic.59af0100.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: NoirPro-Medium;
  font-style: normal;
  src: local(NoirPro-Medium), url("NoirPro-Medium.7ad4f138.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: NoirPro-Regular;
  font-style: normal;
  src: local(NoirPro-Regular), url("NoirPro-Regular.9eb9936a.woff2") format("woff2");
  font-display: block;
}

@font-face {
  font-family: NoirPro-SemiBold;
  font-style: normal;
  src: local(NoirPro-SemiBold), url("NoirPro-SemiBold.ca8f7793.woff2") format("woff2");
  font-display: block;
}

:root {
  font-synthesis: none;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  font-family: NoirPro-Regular, NoirPro-Bold, NoirPro-BoldItalic, NoirPro-Medium, NoirPro-SemiBold, Helvetica, Arial, sans-serif;
}

::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  width: 100vw;
  min-height: 100vh;
  color: #161616;
  -webkit-overflow-scrolling: touch;
  background-color: #5065ac;
  font-size: 12px;
  overflow-x: hidden;
  overflow-y: auto;
}

html {
  color: #161616;
}

.logo {
  z-index: 1;
  min-width: 25px;
  width: 50px;
  will-change: fill;
  margin-right: 64px;
  position: relative;
}

.logocolor {
  fill: #fff;
}

.menucontainer {
  width: 1280px;
  flex-flow: row;
  place-content: center space-between;
  margin: 0 auto;
  display: flex;
}

nav {
  z-index: 1;
  opacity: 0;
  width: 100vw;
  z-index: 99;
  height: 84px;
  background-color: #5065ac;
  align-content: center;
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  position: fixed;
  top: 0;
  box-shadow: 0 3px 5px -2px #03040747, 0 7px 14px -5px #0304074d;
}

.hamburger {
  z-index: 1;
  float: right;
  user-select: none;
  appearance: none;
  cursor: pointer;
  box-shadow: none;
  background: none;
  border: none;
  outline: none;
  display: block;
  position: relative;
}

.hamburger span {
  width: 33px;
  height: 4px;
  z-index: 1;
  transform-origin: 0 0;
  background-color: #fff;
  border-radius: 6px;
  margin-bottom: 5px;
  transition: all .4s;
  display: block;
  position: relative;
}

.hamburger:hover span:nth-child(2) {
  background-color: red;
  transform: translateX(10px);
}

.hamburger.is-active:hover span {
  background-color: red;
}

.hamburger.is-active span:nth-child(1) {
  transform: translate(0, -2px)rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(15px);
}

.hamburger.is-active span:nth-child(3) {
  transform: translate(-3px, 3px)rotate(-45deg);
}

.menu {
  opacity: 1;
  width: 100%;
  height: 400px;
  max-height: calc(100vh - 84px);
  will-change: opacity, transform, box-shadow;
  background-color: #5065ac;
  border-radius: 0 0 49px 49px;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin: 84px auto 0;
  padding: 0;
  display: flex;
  position: fixed;
  left: 0;
  overflow-y: scroll;
  transform: translateY(-500px);
  box-shadow: 0 0 #0000;
}

.menu a {
  color: #fff;
  width: 212px;
  text-align: center;
  opacity: .6;
  cursor: pointer;
  opacity: 0;
  will-change: opacity;
  user-select: none;
  border-radius: 49px;
  margin-right: 12px;
  padding: 18px 0;
  font-family: NoirPro-Regular;
  font-size: 24px;
  font-weight: 400;
  text-decoration: none;
}

.menu .info {
  margin-right: 0;
}

.menu a:hover {
  color: #0ff;
  opacity: 1;
}

.menu a.is-active {
  animation: var(--animation-shake-x) reverse;
  animation-timing-function: var(--ease-2);
  opacity: 0;
  background: linear-gradient(291.87deg, #5359e0 27.6%, #3a3e9d 43.94%, #5359e0 66.28%, #3a3e9d 96.11%) 0 0 / 200% 200%;
  animation-duration: .5s;
  box-shadow: inset 0 0 6px -1px #0000004d;
}

@media (min-width: 1340px) {
  body {
    -webkit-overflow-scrolling: touch;
    font-size: 20px;
    overflow-y: auto;
  }

  .hamburger {
    display: none;
  }

  .menu {
    height: 86px;
    max-height: 86px;
    max-width: fit-content;
    opacity: 1;
    border: 4px solid #fff;
    border-radius: 49px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 0;
    margin-left: 179px;
    display: flex;
    position: relative;
    top: 0;
  }

  .menu a {
    opacity: .6;
  }

  .menu a.is-active {
    opacity: 1;
  }

  .menucontainer {
    margin: 0 auto;
  }

  .logo {
    min-width: 115px;
    margin-right: 64px;
    position: absolute;
    top: 0;
  }

  nav {
    box-shadow: none;
    background-color: #0000;
    padding: 0 32px;
    top: 32px;
  }
}

section {
  height: auto;
  min-height: 100vh;
  width: 100vw;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding-top: 132px;
  display: flex;
  overflow-x: hidden;
}

#home {
  filter: brightness(95%);
  background: linear-gradient(#517bdb 0%, #e3a8eb 62%, #f3ab77 100%);
  overflow: hidden;
}

.containerH {
  width: 86%;
  height: 100%;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "text1 logoc text2";
  grid-auto-columns: 1fr;
  grid-auto-flow: row dense;
  place-content: start center;
  place-items: stretch stretch;
  gap: 0 1%;
  margin: auto 10%;
  padding: 0;
  display: grid;
  position: relative;
}

.text1 {
  color: #fff;
  width: 100%;
  opacity: 0;
  grid-area: text1;
  align-self: start;
}

.text1 h1, .jointext1 h2, .jointext2 h2, .faqtitle h2, .jointext1 p, .jointext2 p {
  color: #fff;
}

h1, h2 {
  text-transform: uppercase;
  font-family: NoirPro-Bold;
  font-size: calc(3.52941vw + .705882px);
  font-weight: 700;
  line-height: calc(5vw - 4px);
}

.Hbold {
  text-transform: uppercase;
  font-family: NoirPro-Bold;
  font-size: clamp(.4rem, 1vw, 1.1rem);
  font-weight: 700;
  line-height: clamp(.5rem, 1.5vw, 2.5rem);
}

.Hboldi {
  font-family: NoirPro-BoldItalic;
  font-size: clamp(.4rem, 1vw, 1.1rem);
  font-weight: 700;
  line-height: clamp(.5rem, 1.5vw, 2.5rem);
}

.Hp {
  font-family: NoirPro-Regular;
  font-size: clamp(.4rem, .95vw, 1.12rem);
  font-weight: 400;
  line-height: clamp(.4rem, 1.5vw, 2.5rem);
}

.list {
  color: #161616;
  font-family: NoirPro-Regular;
  font-size: clamp(.5rem, .8vw, 1rem);
  font-weight: 400;
  line-height: clamp(.4rem, 1.5vw, 2.5rem);
}

.proclinks .Mp {
  text-transform: uppercase;
  color: #161616;
  font-family: NoirPro-Medium;
  font-size: clamp(.5rem, 1.1vw, 1.2rem);
  font-weight: 500;
  line-height: clamp(.5rem, 1.5vw, 2.5rem);
  text-decoration-line: underline;
  list-style-type: circle;
}

.Sp {
  font-family: NoirPro-SemiBold;
  font-size: clamp(.5rem, 1.2vw, 1.3rem);
  font-weight: 600;
  line-height: clamp(.4rem, 1.5vw, 2.5rem);
}

.logoc {
  opacity: 1;
  grid-area: logoc;
}

.logoc .img1 {
  width: 100%;
  height: auto;
  opacity: 0;
  aspect-ratio: 1.86415 / 1;
  position: relative;
  top: -12%;
}

.logoc .img2 {
  width: 30%;
  height: auto;
  opacity: 0;
  filter: brightness(85%);
  z-index: -1;
  position: absolute;
  right: 35%;
}

.text2 {
  color: #fff;
  width: 100%;
  opacity: 0;
  grid-area: text2;
  align-self: start;
  margin-top: 12%;
}

.text2 svg {
  width: 55%;
  cursor: pointer;
  stroke: #fff;
  fill: #fff;
  transition: all .6s;
  transform: translateY(100%);
}

.text2 svg:hover {
  fill: #0ff;
  stroke: #0ff;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #home {
    padding-top: 84px;
  }

  section {
    align-items: center;
    padding-top: 0;
  }

  .containerH {
    width: 95vw;
    height: 100%;
    grid-template: "text1" 1fr
                   "logoc" .45fr
                   "text2" 1fr
                   / 1fr;
    place-content: start;
    place-items: start;
    gap: 0;
    margin: 10% 0 20%;
  }

  .logoc .img1 {
    margin-top: 0;
    top: 0;
  }

  h1, h2 {
    font-size: clamp(2.8rem, 4vw, 4.1rem);
    line-height: clamp(3rem, 4vw, 4.5rem);
  }

  .Hbold, .Hp {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }

  .text1 {
    max-inline-size: 100%;
  }

  .img2 {
    display: none;
  }

  .text2 svg {
    margin-bottom: 0;
    padding: 0;
  }

  .text2 {
    padding-top: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  section {
    min-height: calc(var(--vh, 1vh) * 94);
    justify-content: flex-start;
    align-items: center;
  }

  .containerH {
    width: 95vw;
    height: 100%;
    grid-template: "text1" 1fr
                   "logoc" .8fr
                   "text2" 1fr
                   / 1fr;
    place-content: start;
    place-items: start;
    gap: 0;
    margin: 0;
    padding: 0 5%;
  }

  .logoc {
    align-self: center;
  }

  .logoc .img1 {
    align-content: center;
    align-items: center;
    margin-top: 55px;
    top: 0;
  }

  h1, h2 {
    font-size: clamp(5rem, 4vw, 4.1rem);
    line-height: clamp(5rem, 4vw, 4.5rem);
  }

  .Hbold {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.5rem, 1.5vw, 2.5rem);
  }

  .Hp {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.7rem, 2vw, 2.5rem);
  }

  .text1 {
    max-inline-size: 60%;
  }

  .img2 {
    display: none;
  }

  .text2 svg {
    width: 30%;
    margin-bottom: 0;
    padding: 0;
  }

  .text2 {
    align-content: start;
    align-self: start;
    align-items: start;
    margin-top: 0;
    padding-top: 0;
  }
}

#process {
  width: 100vw;
  background-color: #fff;
  box-shadow: inset 1px 5px 9px -4px #000000b8;
}

.containerproc1 {
  width: 86%;
  z-index: 1;
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "proctext procimg";
  gap: 0;
  margin: auto 10%;
  padding: 0;
  display: grid;
  position: relative;
  overflow: visible;
}

.proctext {
  height: fit-content;
  color: #161616;
  grid-area: proctext;
  align-self: flex-start;
  padding-top: 20%;
}

h1, h2 {
  color: #161616;
}

.procimg1 {
  grid-area: procimg;
  overflow: visible;
}

.procimg1 img {
  max-width: 1123px;
  width: 150%;
  height: auto;
  aspect-ratio: 1123 / 1095;
  grid-area: procimg;
  position: relative;
  top: -20%;
  left: 10%;
}

.containerproc2 {
  width: 86%;
  height: 100%;
  z-index: 0;
  background: linear-gradient(#40e0d0 0%, #40e0d00d 95.96%);
  border-radius: 100px 100px 0 0;
  grid-template-rows: .2fr .35fr .1fr .1fr .6fr .3fr .5fr .4fr;
  grid-template-columns: repeat(4, 1fr);
  grid-template-areas: "proctitle1 proctitle1 proctitle1 proctitle1"
                       "procicon procicon procicon procicon"
                       "proclinktitle proclinktitle proclinktitle proclinktitle"
                       "proclinks proclinks proclinks proclinks"
                       "procimg procimg procimgtitle procimgtitle"
                       "prochowtitle prochowtitle prochowtext prochowtext"
                       "howimg1 howimg2 howimg3 howimg4"
                       "procdiff procdiff procdiff procdiff";
  grid-auto-flow: row;
  gap: 0;
  margin-top: -20%;
  padding-top: 5%;
  padding-left: 4%;
  padding-right: 4%;
  display: grid;
  position: relative;
}

.proctitle1 {
  grid-area: proctitle1;
}

.proctitle1 p {
  white-space: nowrap;
}

.procicon {
  height: auto;
  flex-flow: row;
  grid-area: procicon;
  justify-content: space-between;
  display: flex;
}

.procicon div {
  width: 100%;
  flex-flow: column;
  justify-content: space-between;
  display: flex;
}

.procicon div img {
  max-width: 380px;
  width: 100%;
  height: auto;
  aspect-ratio: 1.58025 / 1;
}

.proclinktitle {
  white-space: nowrap;
  grid-area: proclinktitle;
  padding-top: 20px;
  padding-bottom: 20px;
}

.proclinks {
  flex-flow: row;
  grid-area: proclinks;
  gap: 5%;
  padding-bottom: 6%;
  display: flex;
}

.proclink1 {
  max-width: 282px;
  width: 84%;
  height: auto;
}

.proclink1 img {
  width: 100%;
  height: auto;
  aspect-ratio: 127 / 10;
}

.proclink2 {
  max-width: 340px;
  width: 100%;
  height: auto;
  flex-flow: column wrap;
  gap: 80%;
  display: flex;
}

.proclink2 img {
  width: 100%;
  height: auto;
  aspect-ratio: 302 / 19;
}

.proclink2b img {
  width: 100%;
  height: auto;
  aspect-ratio: 302 / 19;
  margin-top: 10%;
}

.proclink3 {
  max-width: 254px;
  width: 76%;
  height: auto;
}

.proclink3 img {
  width: 100%;
  height: auto;
  aspect-ratio: 229 / 20;
}

.proclink4 {
  max-width: 179px;
  width: 55%;
  height: auto;
}

.proclink4 img {
  width: 100%;
  height: auto;
  aspect-ratio: 83 / 10;
}

.proclink5 {
  max-width: 269px;
  width: 82%;
  height: auto;
}

.proclink5 img {
  width: 100%;
  height: auto;
  aspect-ratio: 247 / 20;
}

.proclink5:hover, .proclink4:hover, .proclink3:hover, .proclink1:hover, .proclink2a:hover, .proclink2b:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.procimg2 {
  max-width: 735px;
  width: 100%;
  height: auto;
  grid-area: procimg;
}

.procimg2 img {
  width: 98%;
  height: auto;
  aspect-ratio: 735 / 801;
}

.procimgtitle {
  grid-area: procimgtitle;
  align-self: center;
  padding-left: 4%;
}

.procimgtitle svg {
  width: 25%;
  cursor: pointer;
  stroke: #000;
  fill: #000;
  transition: all .6s;
  transform: translateY(100%);
}

.procimgtitle svg:hover {
  fill: #fff;
  stroke: #fff;
}

.prochowtitle {
  grid-area: prochowtitle;
  align-self: center;
  padding: 12% 0 10%;
}

.prochowtext {
  grid-area: prochowtext;
  align-self: center;
  padding: 12% 0 10% 4%;
}

.howimg1 {
  width: 100%;
  height: auto;
  grid-area: howimg1;
  padding-left: 7%;
}

.howimg2 {
  width: 100%;
  height: auto;
  grid-area: howimg2;
  padding-left: 7%;
}

.howimg3 {
  width: 100%;
  height: auto;
  grid-area: howimg3;
  padding-left: 7%;
}

.howimg4 {
  width: 100%;
  height: auto;
  grid-area: howimg4;
  padding-left: 7%;
}

.procdiff {
  color: #5359e0;
  background: linear-gradient(270deg, #fff3 0%, #0000 56.59%), linear-gradient(#3a3e9d40 23.25%, #40e0d073 100%);
  border: 4px solid #40e0d0;
  border-radius: 20px;
  grid-area: procdiff;
  align-self: center;
  margin: 8% 0 0;
  padding: 2%;
}

.procdiff h1, .procdiff h2 {
  color: #5359e0;
}

.procdifflinkc {
  white-space: normal;
  max-inline-size: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}

.procdifflinkc p {
  max-inline-size: 100%;
}

.procdifflink {
  max-width: 180px;
  width: 18%;
  height: auto;
  align-self: center;
  position: relative;
}

.procdifflink img {
  width: 100%;
  height: auto;
  aspect-ratio: 172 / 21;
}

.procdifflink:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.card1 {
  max-width: 335px;
  width: 100%;
  height: 88%;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5%;
  display: block;
  overflow: hidden;
}

.card1 img, .card2 img, .card3 img, .card4 img {
  width: 100%;
  height: auto;
  clip-path: inset(0 1%);
  aspect-ratio: .867876 / 1;
}

.card2, .card3 {
  max-width: 335px;
  width: 100%;
  height: 88%;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5%;
  display: block;
  overflow: hidden;
}

.card4 {
  max-width: 335px;
  width: 100%;
  height: 88%;
  cursor: pointer;
  background-color: #fff;
  border-radius: 5%;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

@media screen and (pointer: fine) {
  .card1:hover, .card2:hover, .card3:hover, .card4:hover {
    animation: pulse 1.5s var(--ease-5);
  }
}

.Ctitle {
  text-align: center;
  margin-top: 10%;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .containerproc1 {
    width: 95vw;
    grid-template: "proctext" 1fr
    / 1fr;
    margin: 10% 0;
  }

  .procimg1 {
    display: none;
  }

  .proctext {
    padding-top: 0;
  }

  .containerproc2 {
    width: 100vw;
    border-radius: 50px 50px 0 0;
    grid-template-rows: .1fr .1fr .1fr .1fr .6fr .1fr .12fr .5fr .5fr .5fr .5fr .35fr;
    grid-template-areas: "proctitle1 proctitle1 proctitle1 proctitle1"
                         "procicon procicon procicon procicon"
                         "proclinktitle proclinktitle proclinktitle proclinktitle"
                         "proclinks proclinks proclinks proclinks"
                         "procimgtitle procimgtitle procimgtitle procimgtitle"
                         "prochowtitle prochowtitle prochowtitle prochowtitle"
                         "prochowtext prochowtext prochowtext prochowtext"
                         "howimg1 howimg1 howimg1 howimg1"
                         "howimg2 howimg2 howimg2 howimg2"
                         "howimg3 howimg3 howimg3 howimg3"
                         "howimg4 howimg4 howimg4 howimg4"
                         "procdiff procdiff procdiff procdiff";
    margin-top: 0;
    padding-top: 10%;
  }

  .proctitle1 p {
    white-space: normal;
  }

  .procimg2 {
    display: none;
  }

  .procicon {
    flex-flow: column wrap;
  }

  .procicon div {
    width: 100%;
    flex-flow: column wrap;
  }

  .procicon div img {
    min-width: 250px;
    padding-bottom: 15px;
  }

  .proclinks {
    flex-wrap: wrap;
    gap: 0;
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .proclink1 {
    width: 100%;
    padding-bottom: 34px;
  }

  .proclink2 {
    padding-bottom: 34px;
    display: block;
    position: relative;
    left: -2px;
  }

  .proclink3, .proclink4 {
    width: 100%;
    padding-bottom: 34px;
  }

  .proclink5 {
    width: 100%;
  }

  .procimgtitle {
    padding-left: 0;
  }

  .Hboldi {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }

  .prochowtitle {
    padding-top: 80px;
    padding-bottom: 10px;
  }

  .prochowtext {
    padding-bottom: 40px;
    padding-left: 0;
  }

  .procimgtitle svg {
    width: 40%;
  }

  .Sp {
    font-size: clamp(1rem, 1.2vw, 1.3rem);
    line-height: clamp(1rem, 1.5vw, 2.5rem);
  }

  .howimg1, .howimg2, .howimg3, .howimg4 {
    padding-bottom: 20px;
    padding-left: 0;
  }

  .card1, .card2 {
    float: none;
    margin: 0 auto;
  }

  .card1 img, .card2 img, .card3 img, .card4 img {
    width: 100%;
    height: auto;
  }

  .card4, .card3 {
    float: none;
    margin: 0 auto;
  }

  .procdiff {
    margin-bottom: 20px;
    padding: 4%;
  }

  .procdifflinkc {
    white-space: normal;
    flex-direction: column;
  }

  .procdifflink {
    width: 100%;
    align-self: flex-start;
    padding-top: 10px;
  }

  .proclinktitle {
    white-space: normal;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #process {
    padding-top: 0;
  }

  .containerproc1 {
    width: 86%;
    z-index: 1;
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "proctext procimg";
    gap: 0;
    margin: auto 10%;
    padding: 0;
    display: grid;
    position: relative;
    overflow: visible;
  }

  .proctext {
    height: fit-content;
    grid-area: proctext;
    align-self: flex-start;
    padding-top: 20%;
  }

  .procimg1 {
    grid-area: procimg;
    overflow: visible;
  }

  .procimg1 img {
    max-width: 1123px;
    width: 150%;
    height: auto;
    position: relative;
    top: 10%;
    left: 10%;
  }

  .containerproc2 {
    width: 86%;
    height: 100%;
    z-index: 0;
    background: linear-gradient(#40e0d0 0%, #40e0d00d 95.96%);
    border-radius: 100px 100px 0 0;
    grid-template-rows: .2fr .35fr .1fr .1fr .6fr .3fr .5fr .4fr;
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "proctitle1 proctitle1 proctitle1 proctitle1"
                         "procicon procicon procicon procicon"
                         "proclinktitle proclinktitle proclinktitle proclinktitle"
                         "proclinks proclinks proclinks proclinks"
                         "procimg procimgtitle procimgtitle procimgtitle"
                         "prochowtitle prochowtitle prochowtext prochowtext"
                         "howimg1 howimg2 howimg3 howimg4"
                         "procdiff procdiff procdiff procdiff";
    grid-auto-flow: row;
    gap: 0;
    margin-top: 80px;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 4%;
    display: grid;
    position: relative;
  }

  .proctitle1 {
    grid-area: proctitle1;
  }

  .proctitle1 p {
    white-space: normal;
  }

  .procicon {
    height: auto;
    flex-flow: row;
    grid-area: procicon;
    justify-content: space-between;
    display: flex;
  }

  .procicon div {
    width: 100%;
    flex-flow: column;
    justify-content: space-between;
    display: flex;
  }

  .procicon div img {
    max-width: 380px;
    width: 100%;
    height: auto;
  }

  .proclinktitle {
    white-space: normal;
    grid-area: proclinktitle;
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .proclinks {
    flex-flow: row;
    grid-area: proclinks;
    gap: 5%;
    padding-bottom: 6%;
    display: flex;
  }

  .proclink1 {
    max-width: 282px;
    width: 84%;
    height: auto;
  }

  .proclink1 img {
    width: 100%;
    height: auto;
  }

  .proclink2 {
    max-width: 340px;
    width: 100%;
    height: auto;
    flex-flow: column wrap;
    gap: 80%;
    display: flex;
  }

  .proclink2 img {
    width: 100%;
    height: auto;
  }

  .proclink2b img {
    width: 100%;
    height: auto;
    margin-top: 10%;
  }

  .proclink3 {
    max-width: 254px;
    width: 76%;
    height: auto;
  }

  .proclink3 img {
    width: 100%;
    height: auto;
  }

  .proclink4 {
    max-width: 179px;
    width: 55%;
    height: auto;
  }

  .proclink4 img {
    width: 100%;
    height: auto;
  }

  .proclink5 {
    max-width: 269px;
    width: 82%;
    height: auto;
  }

  .proclink5 img {
    width: 100%;
    height: auto;
  }

  .proclink5:hover, .proclink4:hover, .proclink3:hover, .proclink1:hover, .proclink2a:hover, .proclink2b:hover {
    animation: shake-x 1.5s var(--ease-5);
  }

  .procimg2 {
    max-width: 735px;
    width: 100%;
    height: auto;
    grid-area: procimg;
  }

  .procimg2 img {
    width: 100%;
    height: auto;
  }

  .procimgtitle {
    grid-area: procimgtitle;
    align-self: center;
    padding-left: 4%;
  }

  .procimgtitle svg {
    width: 20%;
    cursor: pointer;
    stroke: #000;
    fill: #000;
    transition: all .6s;
    transform: translateY(100%);
  }

  .procimgtitle svg:hover {
    fill: #fff;
    stroke: #fff;
  }

  .prochowtitle {
    grid-area: prochowtitle;
    align-self: center;
    padding: 12% 0 10%;
  }

  .prochowtext {
    grid-area: prochowtext;
    align-self: center;
    padding: 12% 0 10% 4%;
  }

  .howimg1 {
    width: 100%;
    height: auto;
    grid-area: howimg1;
    padding-left: 7%;
  }

  .howimg2 {
    width: 100%;
    height: auto;
    grid-area: howimg2;
    padding-left: 7%;
  }

  .howimg3 {
    width: 100%;
    height: auto;
    grid-area: howimg3;
    padding-left: 7%;
  }

  .howimg4 {
    width: 100%;
    height: auto;
    grid-area: howimg4;
    padding-left: 7%;
  }

  .procdiff {
    background: linear-gradient(270deg, #fff3 0%, #0000 56.59%), linear-gradient(#3a3e9d40 23.25%, #40e0d073 100%);
    border: 4px solid #40e0d0;
    border-radius: 20px;
    grid-area: procdiff;
    align-self: center;
    margin: 8% 0 0;
    padding: 2%;
  }

  .procdifflinkc {
    white-space: normal;
    max-inline-size: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
  }

  .procdifflinkc p {
    max-inline-size: 70%;
  }

  .procdifflink {
    max-width: 180px;
    width: 25%;
    height: auto;
    align-self: center;
    position: relative;
  }

  .procdifflink img {
    width: 100%;
    height: auto;
  }

  .procdifflink:hover {
    animation: shake-x 1.5s var(--ease-5);
  }

  .card1 {
    max-width: 335px;
    width: 100%;
    height: 88%;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5%;
    display: block;
    overflow: hidden;
  }

  .card1 img, .card2 img, .card3 img, .card4 img {
    width: 100%;
    height: auto;
  }

  .card2, .card3 {
    max-width: 335px;
    width: 100%;
    height: 88%;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5%;
    display: block;
    overflow: hidden;
  }

  .card4 {
    max-width: 335px;
    width: 100%;
    height: 88%;
    cursor: pointer;
    background-color: #fff;
    border-radius: 5%;
    margin: 0 auto;
    display: block;
    overflow: hidden;
  }

  .card1:hover, .card2:hover, .card3:hover, .card4:hover {
    animation: pulse 1.5s var(--ease-5);
  }

  .Ctitle {
    text-align: center;
    margin-top: 10%;
  }
}

#joinus {
  background-image: url("joinusback.5d8394a8.webp");
  background-position: 100%;
  background-size: cover;
  border: 1px solid #000;
  padding: 7%;
}

.joincontainer {
  width: 100%;
  height: 100%;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr;
  grid-template-areas: "jointext1"
                       "jointext2";
  grid-auto-flow: row;
  place-content: start;
  place-items: start;
  gap: 15% 0;
  display: grid;
}

.jointext1 {
  grid-area: jointext1;
  place-self: start;
}

.joinlink {
  max-width: 200px;
  width: 45%;
  height: auto;
}

.joinlink:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.joinlink img {
  width: 100%;
  height: 100%;
  aspect-ratio: 196 / 25;
}

.jointext2 {
  background: linear-gradient(0deg, #3a3e9d73 23.25%, #40e0d040 100%);
  border: 4px solid #535ce0;
  border-radius: 20px;
  grid-area: jointext2;
  place-self: start;
  padding: 2%;
}

.jointext2 p {
  max-inline-size: 100%;
}

.joinlink2 {
  max-width: 264px;
  width: 30%;
  height: auto;
}

.joinlink2:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.joinlink2 img {
  width: 100%;
  height: 100%;
  aspect-ratio: 88 / 9;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #joinus {
    background-position: 0;
  }

  .joincontainer {
    width: 90vw;
    grid-template-rows: .95fr 1fr;
  }

  .joinlink {
    width: 50%;
  }

  .joinlink2 {
    width: 70%;
    padding-bottom: 20px;
  }

  .jointext2 {
    width: 90vw;
    padding: 2%;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .joincontainer {
    width: 90vw;
    grid-template-rows: .6fr 1fr;
    gap: 0;
    padding-top: 100px;
  }

  .joinlink2 {
    width: 70%;
    padding-bottom: 0;
  }
}

#team {
  color: #161616;
  background-color: #fff;
  background-image: url("team_back.a17bd0d5.webp");
  background-position: 100%;
  background-size: cover;
}

.teamcontainer {
  width: 86%;
  height: 100%;
  grid-template-rows: .1fr 1fr .6fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "teamtitle teamtitle teamtitle"
                       "team1img team2img team3img"
                       "team1text teamt2text team3text";
  grid-auto-flow: row;
  gap: 1% 3%;
  display: grid;
}

.teamtitle {
  grid-area: teamtitle;
  place-self: flex-start center;
}

.team1img {
  max-width: 469px;
  width: 100%;
  height: auto;
  grid-area: team1img;
  place-self: center;
}

.team1img img, .team2img img, .team3img img {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
}

.team2img {
  grid-area: team2img;
  place-self: center;
}

.team3img {
  grid-area: team3img;
  place-self: center;
}

.team1text {
  grid-area: team1text;
  place-self: start center;
  padding: 0 5%;
}

.teamt2text {
  grid-area: teamt2text;
  place-self: start center;
  padding: 0 5%;
}

.team3text {
  grid-area: team3text;
  place-self: start center;
  padding: 0 5%;
}

.teamname {
  text-transform: uppercase;
  font-family: NoirPro-Bold;
  font-size: clamp(.5rem, 1.1vw, 1.2rem);
  font-weight: 700;
  line-height: clamp(.5rem, 1.5vw, 2.5rem);
}

.teamposition {
  font-family: NoirPro-SemiBold;
  font-size: clamp(.4rem, 1.1vw, 1.2rem);
  font-weight: 600;
  line-height: clamp(.4rem, 1.5vw, 2.5rem);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #team {
    padding-bottom: 60px;
  }

  .teamcontainer {
    width: 100vw;
    grid-template-rows: .2fr 1fr .5fr 1fr .5fr 1fr .5fr;
    grid-template-areas: "teamtitle teamtitle teamtitle"
                         "team1img team1img team1img"
                         "team1text team1text team1text"
                         "team2img team2img team2img"
                         "teamt2text teamt2text teamt2text"
                         "team3img team3img team3img"
                         "team3text team3text team3text";
    gap: 1%;
    padding: 10% 4%;
  }

  .team1text, .teamt2text {
    padding: 0 5% 5%;
  }

  .team3text {
    padding: 0 5%;
  }

  .teamname {
    font-size: clamp(1.2rem, 1.1vw, 1.2rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }

  .teamposition {
    font-size: clamp(1rem, 1.1vw, 1.2rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #team {
    padding-bottom: 60px;
  }

  .teamcontainer {
    width: 100vw;
    grid-template-rows: .2fr 1fr .5fr 1fr .5fr 1fr .5fr;
    grid-template-areas: "teamtitle teamtitle teamtitle"
                         "team1img team1img team1img"
                         "team1text team1text team1text"
                         "team2img team2img team2img"
                         "teamt2text teamt2text teamt2text"
                         "team3img team3img team3img"
                         "team3text team3text team3text";
    gap: 1%;
    padding: 0 4%;
  }

  .team1text, .teamt2text {
    text-align: center;
    padding: 0 5% 5%;
  }

  .team3text {
    text-align: center;
    padding: 0 5%;
  }

  .teamname {
    font-size: clamp(2rem, 1.1vw, 1.2rem);
    line-height: clamp(2.2rem, 1.5vw, 2.5rem);
  }

  .teamposition {
    font-size: clamp(1.6rem, 1.1vw, 1.2rem);
    line-height: clamp(1.9rem, 1.5vw, 2.5rem);
  }
}

#info {
  background-color: #fff;
}

.faqcontainer {
  width: 86%;
  height: 100%;
  background: linear-gradient(#5359e0 0%, #5359e00d 95.96%);
  border-radius: 50px 50px 0 0;
  grid-template-rows: .1fr .1fr;
  grid-template-columns: repeat(3, 1fr);
  grid-template-areas: "faqtitle faqtitle faqtitle"
                       "faq1 faq2 faq3";
  grid-auto-flow: row;
  gap: 3%;
  padding: 3% 3% 6%;
  display: grid;
}

.faqtitle {
  grid-area: faqtitle;
  place-self: flex-start center;
  padding-bottom: 3%;
}

.faq1 {
  grid-area: faq1;
  place-self: center;
}

.faq2 {
  grid-area: faq2;
  place-self: center;
}

.faq3 {
  grid-area: faq3;
  place-self: center;
}

.faq11container {
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: repeat(2, 1fr);
  grid-template-areas: "toptext toptext"
                       "list1 list2";
  grid-auto-flow: row;
  gap: 1%;
  display: grid;
}

.toptext {
  grid-area: toptext;
  place-self: center start;
}

.list1 {
  grid-area: list1;
  justify-self: center;
}

.list2 {
  grid-area: list2;
  justify-self: center;
}

.accordion-header {
  cursor: pointer;
  color: #181818;
  background-color: #fff;
  border-radius: 4px;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  transition: all .4s ease-in-out;
  display: flex;
}

.accordion-header:hover {
  animation: blink 1s var(--ease-5);
}

.accordion-header h3 {
  color: #181818;
  max-inline-size: 100%;
  font-size: clamp(.5rem, .8vw, 1rem);
  font-weight: 600;
}

.accordion-body {
  max-height: 0;
  background-color: #fcfcfc;
  border-radius: 0 0 4px 4px;
  transition: all .35s ease-in-out;
  overflow: hidden;
}

.accordion-body p {
  color: #181818;
  padding: 1rem 1.5rem;
  font-size: clamp(.5rem, .8vw, 1rem);
  font-weight: 600;
  line-height: 1.8;
}

.accordion + .accordion {
  margin-top: 1rem;
}

.accordion-icon {
  transition: transform .4s ease-in-out;
}

.accordion.active .accordion-header {
  background-color: #fff;
  border-radius: 4px 4px 0 0;
}

.accordion.active .accordion-icon {
  transform: rotate(-180deg);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #info {
    padding-top: 50px;
  }

  .faqcontainer {
    width: 100%;
    border-radius: 50px 50px 0 0;
    grid-template-rows: 60px 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "faqtitle"
                         "faq1"
                         "faq2"
                         "faq3";
    gap: 15px 0;
    padding: 10% 4% 6%;
  }

  .list1, .list2 {
    padding-left: 1.5rem;
  }

  .faqtitle {
    padding-bottom: 0;
  }

  .accordion-header h3 {
    font-size: clamp(1rem, .8vw, 1rem);
  }

  .accordion-body p {
    padding: 1rem .5rem;
    font-size: clamp(1rem, .8vw, 1rem);
    line-height: 1.5;
  }

  .list {
    padding: 0 .5rem;
    font-size: clamp(1rem, .8vw, 1rem);
    line-height: clamp(1.4rem, 1.5vw, 2.5rem);
  }

  .accordion-header {
    width: 100%;
    padding: 1rem .4rem;
  }

  .faq1, .faq2, .faq3 {
    width: 100%;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #info {
    padding-top: 50px;
  }

  .faqcontainer {
    width: 90%;
    border-radius: 50px 50px 0 0;
    grid-template-rows: 160px 1fr;
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas: "faqtitle"
                         "faq1"
                         "faq2"
                         "faq3";
    gap: 15px 0;
    padding: 10% 4% 6%;
  }

  .list1, .list2 {
    padding-left: 1.5rem;
  }

  .faqtitle {
    padding-bottom: 0;
  }

  .accordion-header h3 {
    max-inline-size: 100%;
    font-size: clamp(1rem, .8vw, 1rem);
  }

  .accordion-body p {
    padding: 1rem .5rem;
    font-size: clamp(1rem, .8vw, 1rem);
    line-height: 1.5;
  }

  .list {
    padding: 0 .5rem;
    font-size: clamp(1rem, .8vw, 1rem);
    line-height: clamp(1.4rem, 1.5vw, 2.5rem);
  }

  .accordion-header {
    width: 100%;
    padding: 1rem;
  }

  .faq1, .faq2, .faq3 {
    width: 80%;
  }
}

.footer {
  width: 100vw;
  height: 200px;
  background: linear-gradient(#517bdb 0%, #e3a8eb 100%);
  box-shadow: inset 0 5px 10px -4px #000c;
}

.footover {
  height: 100%;
  width: 100vw;
  background: linear-gradient(89.97deg, #20232600 .02%, #20232680 99.96%);
}

.footcont {
  width: 80%;
  height: 100%;
  flex-direction: row;
  align-items: center;
  margin-left: min(15rem, 10%);
  margin-right: min(15rem, 10%);
  display: flex;
}

.footpad {
  padding-right: 10%;
}

.footimg1 {
  max-width: 123px;
  width: 100%;
  height: auto;
  aspect-ratio: 123 / 19;
}

.footimg2 {
  max-width: 138px;
  width: 100%;
  height: auto;
  aspect-ratio: 137 / 22;
}

.footimg3 {
  max-width: 181px;
  width: 100%;
  height: auto;
  aspect-ratio: 181 / 22;
}

.footimg4 {
  max-width: 186px;
  width: 100%;
  height: auto;
  aspect-ratio: 1.86415 / 1;
}

.footimg5 {
  max-width: 271px;
  width: 100%;
  height: auto;
  aspect-ratio: 271 / 22;
}

.footlast {
  padding-right: 0;
}

.footcont:nth-child(1) img:hover {
  animation: shake-x 1.5s var(--ease-5);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer {
    width: 100%;
    height: 430px;
  }

  .footcont {
    height: 80%;
    flex-direction: column;
    align-items: center;
    gap: 40px 0;
    padding-top: 40px;
    display: flex;
  }

  .footpad {
    padding-right: 0;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .footer {
    width: 100%;
    height: 230px;
  }

  .footcont {
    height: 80%;
    flex-direction: row;
    align-items: center;
    gap: 40px 0;
    padding-top: 40px;
    display: flex;
  }

  .footpad {
    padding-right: 30px;
  }
}

.modal__close {
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
  z-index: 1;
  position: absolute;
  top: 1em;
  right: 1em;
}

.modal__close:after, .modal__close:before {
  content: "";
  width: 4px;
  height: 1.5em;
  background: #3c50ff;
  border-radius: 40px;
  margin: -3px 0 0 -1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  transform: rotate(45deg);
}

.modal__close:hover:after, .modal__close:hover:before {
  background: #ff5353;
}

.modal__close:before {
  transform: rotate(-45deg);
}

.modal-wrapper1 {
  width: 100vw;
  height: 100vh;
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
  background: #5d008299;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.modal-wrapper2 {
  width: 100%;
  height: 100%;
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
  background: #1a00aa99;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.modal-wrapper3 {
  width: 100%;
  height: 100%;
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
  background: #aa00a299;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.modal-wrapper4 {
  width: 100%;
  height: 100%;
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
  background: #aa6f0099;
  justify-content: center;
  align-items: center;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(100%);
}

.modal {
  width: 70vw;
  min-height: 330px;
  height: fit-content;
  opacity: 0;
  box-shadow: var(--shadow-3);
  will-change: opacity, transform;
  background: #fff;
  border-radius: 12px;
  padding: 50px 24px 24px;
  overflow-y: auto;
  transform: translateY(20%);
}

.modal__title {
  color: #00449e;
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 1.25rem;
  font-weight: 600;
  line-height: 1.25;
  position: absolute;
  top: 20px;
  left: 20px;
}

.modal__content {
  color: #000c;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 1.5;
  display: flex;
}

.modaltext {
  z-index: 1;
  grid-area: modaltext;
  place-self: stretch center;
}

.modaltext p {
  max-inline-size: 70%;
}

.modalimg {
  max-width: 280px;
  width: 35%;
  height: auto;
  z-index: 0;
  clip-path: inset(0 1%);
  aspect-ratio: .867876 / 1;
  position: absolute;
  bottom: 0;
  right: 10px;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .modalimg {
    display: none;
  }

  .modal__content {
    grid-template: "modaltext" 1fr
    / 1fr;
    grid-auto-flow: row;
    display: grid;
  }

  .modal {
    width: 90vw;
  }

  .modaltext p {
    max-inline-size: 90%;
  }
}

@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .modal__content {
    grid-template: "modaltext modalimg" 1fr
    / 1fr .5fr;
    grid-auto-flow: row;
    display: grid;
  }

  .modal {
    width: 90vw;
  }

  .modaltext p {
    max-inline-size: 90%;
  }
}

@media (pointer: fine) {
  .cursor .cursor--small, .cursor .cursor--large, .cursor .cursor--text {
    width: var(--cursor-size);
    height: var(--cursor-size);
    mix-blend-mode: difference;
    pointer-events: none;
    user-select: none;
    z-index: 301;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
  }

  .cursor .cursor--text {
    --cursor-size: fit-content;
    color: #fff;
    opacity: 0;
    font-size: .75rem;
  }

  .cursor .cursor--text .text {
    font-family: NoirPro-Medium;
  }

  .cursor .cursor--small {
    --cursor-size: 20px;
    background: #fff;
  }

  .cursor .cursor--large {
    --cursor-size: 60px;
    border: 2px solid #fff;
  }
}

@media (pointer: coarse) {
  .cursor, .cursor--text, .text, .cursor--small, .cursor--large {
    display: none;
  }
}

.titletesti {
  padding-left: 4%;
  padding-right: 4%;
}

.splide {
  width: 92%;
  margin-bottom: 20px;
  position: relative;
}

.splide:before {
  content: "";
  z-index: 1;
  pointer-events: none;
  background: linear-gradient(90deg, #fff 5%, #0000 30% 70%, #fff 95%);
  position: absolute;
  inset: 0;
}

.splide__track {
  cursor: grab;
  border-radius: 20px;
}

.splide__progress {
  width: 40%;
  background-color: #fdfdfd;
  border-radius: 20px;
  margin: 0 auto;
  box-shadow: inset 0 2px 4px -1px #0006;
}

.splide__progress__bar {
  height: 10px;
  width: 0;
  background: linear-gradient(90deg, #00ffbd, #1337ff) 0 0 / 300% 100%;
  border-radius: 20px;
  margin-top: 1rem;
  transition: width .1s;
  box-shadow: inset 0 2px 4px -1px #0006;
}

.animatebar {
  animation: AnimationName 20.6s infinite;
}

@keyframes AnimationName {
  0% {
    background-position: 100%;
  }

  96% {
    background-position: 0%;
  }

  100% {
    background-position: 100%;
  }
}

.splide__slide.is-active {
  box-shadow: var(--shadow-2);
  filter: none;
}

.splide__slide {
  width: 80%;
  color: #fff;
  max-inline-size: 100%;
  box-shadow: none;
  filter: grayscale(60%) blur(2px);
  border-radius: 20px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: .5rem;
  margin-bottom: 20px;
  padding: 3rem;
  transition: box-shadow 1s, filter .5s;
  display: flex;
}

.splide__slide h2 {
  background: var(--gradient-11);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transform-style: preserve-3d;
  background-clip: text;
  position: relative;
}

.splide__slide h2:before {
  border-radius: var(--radius-blob-3);
  width: var(--size-fluid-6);
  height: var(--size-fluid-5);
  content: "";
  background: var(--gradient-22);
  position: absolute;
  top: 0;
  left: -10px;
  transform: translateZ(-1px);
}

.splide__slide p {
  max-inline-size: 100%;
  position: relative;
}

.slide1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.slide2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.slide3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.slide4 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.slide5 {
  background: radial-gradient(#f588d8, #c0a3e5);
}

.slide6 {
  background: radial-gradient(#ff82a1, #fbc1cc);
}

.slide7 {
  background: radial-gradient(#ff1fda, #fa99b2);
}

.splide__pagination__page {
  background-color: #fdfdfd;
  box-shadow: inset 0 2px 4px -1px #0006;
}

.splide__pagination__page.is-active {
  background-color: #5359e0;
}

.splide__pagination__page:hover {
  background-color: #1fe4f5;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .splide {
    width: 115vw;
  }

  .splide__slide {
    width: 110%;
    filter: none;
    padding: 1.5rem;
    transition: box-shadow .4s;
  }

  .splide__slide h2:before {
    width: 70px;
    height: 50px;
  }

  .splide .splide__list {
    transition: none;
  }

  .splide:before {
    content: "";
    background: none;
  }

  .splide__slide {
    justify-content: center;
  }

  .splide__progress {
    width: 55%;
    border-radius: 10px;
    margin: 20px auto;
  }
}

/*# sourceMappingURL=index.a4f6d239.css.map */
