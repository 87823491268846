@import '/src/css/open-props.min.css';
@import '/src/css/normalize.min.css';
@import '/src/css/remixicon.css';
@import '/src/css/splide-core.min.css';
@font-face {
  font-family: "NoirPro-Bold";
  font-style: normal;
  src: local("NoirPro-Bold"), url(/src/fonts/NoirPro-Bold.woff2) format("woff2");
  font-display: block;
}
@font-face {
  font-family: "NoirPro-BoldItalic";
  font-style: normal;
  src: local("NoirPro-BoldItalic"), url(/src/fonts/NoirPro-BoldItalic.woff2) format("woff2");
  font-display: block;
}
@font-face {
  font-family: "NoirPro-Medium";
  font-style: normal;
  src: local("NoirPro-Medium"), url(/src/fonts/NoirPro-Medium.woff2) format("woff2");
  font-display: block;
}
@font-face {
  font-family: "NoirPro-Regular";
  font-style: normal;
  src: local("NoirPro-Regular"), url(/src/fonts/NoirPro-Regular.woff2) format("woff2");
  font-display: block;
}
@font-face {
  font-family: "NoirPro-SemiBold";
  font-style: normal;
  src: local("NoirPro-SemiBold"), url(/src/fonts/NoirPro-SemiBold.woff2) format("woff2");
  font-display: block;
}
:root {
  font-family: NoirPro-Regular, NoirPro-Bold, NoirPro-BoldItalic, NoirPro-Medium, NoirPro-SemiBold, Helvetica, Arial, sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

::-webkit-scrollbar {
  display: none;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 12px;
  background-color: #5065ac;
  overflow-x: hidden;
  width: 100vw;
  min-height: 100vh;
  color: #161616;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

html {
  color: #161616;
}

.logo {
  z-index: 1;
  position: relative;
  min-width: 25px;
  width: 50px;
  margin-right: 64px;
  will-change: fill;
}

.logocolor {
  fill: hsl(0deg, 100%, 100%);
}

.menucontainer {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
}

nav {
  z-index: 1;
  opacity: 0;
  position: fixed;
  margin: 0 auto;
  width: 100vw;
  top: 0;
  z-index: 99;
  padding: 0 30px;
  display: flex;
  align-content: center;
  background-color: #5065ac;
  box-shadow: 0 3px 5px -2px rgba(3, 4, 7, 0.28), 0 7px 14px -5px rgba(3, 4, 7, 0.3);
  height: 84px;
}

.hamburger {
  display: block;
  position: relative;
  z-index: 1;
  float: right;
  user-select: none;
  appearance: none;
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  border: none;
  box-shadow: none;
}

.hamburger span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background-color: #fff;
  border-radius: 6px;
  z-index: 1;
  transform-origin: 0 0;
  transition: 0.4s;
}

.hamburger:hover span:nth-child(2) {
  transform: translateX(10px);
  background-color: red;
}

.hamburger.is-active:hover span {
  background-color: red;
}

.hamburger.is-active span:nth-child(1) {
  transform: translate(0, -2px) rotate(45deg);
}

.hamburger.is-active span:nth-child(2) {
  opacity: 0;
  transform: translateX(15px);
}

.hamburger.is-active span:nth-child(3) {
  transform: translate(-3px, 3px) rotate(-45deg);
}

.menu {
  display: flex;
  opacity: 1;
  position: fixed;
  left: 0;
  flex: 1 1 0%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: #5065ac;
  padding: 0;
  width: 100%;
  border-radius: 0 0 49px 49px;
  margin-top: 84px;
  transform: translateY(-500px);
  height: 400px;
  max-height: calc(100vh - 84px);
  overflow-y: scroll;
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
  will-change: opacity, transform, box-shadow;
}

.menu a {
  color: #fff;
  margin-right: 12px;
  font-family: NoirPro-Regular;
  font-weight: 400;
  font-size: 24px;
  text-decoration: none;
  padding: 18px 0px;
  border-radius: 49px;
  width: 212px;
  text-align: center;
  opacity: 0.6;
  cursor: pointer;
  opacity: 0;
  will-change: opacity;
  user-select: none;
}

.menu .info {
  margin-right: 0;
}

.menu a:hover {
  color: aqua;
  opacity: 1;
}

.menu a.is-active {
  background: linear-gradient(291.87deg, #5359e0 27.6%, #3a3e9d 43.94%, #5359e0 66.28%, #3a3e9d 96.11%);
  background-size: 200% 200%;
  animation: var(--animation-shake-x) reverse;
  animation-timing-function: var(--ease-2);
  animation-duration: 0.5s;
  opacity: 0;
  box-shadow: inset 0px 0 6px -1px rgba(0, 0, 0, 0.3);
}

@media (min-width: 1340px) {
  body {
    font-size: 20px;
    -webkit-overflow-scrolling: touch;
    overflow-y: auto;
  }
  .hamburger {
    display: none;
  }
  .menu {
    top: 0;
    margin-top: 0;
    margin-left: 179;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 86px;
    max-height: 86px;
    border-radius: 49px;
    max-width: fit-content;
    border: 4px solid white;
    flex-direction: row;
    opacity: 1;
  }
  .menu a {
    opacity: 0.6;
  }
  .menu a.is-active {
    opacity: 1;
  }
  .menucontainer {
    margin: 0 auto;
  }
  .logo {
    min-width: 115px;
    top: 0;
    position: absolute;
    margin-right: 64px;
  }
  nav {
    padding: 0 32px;
    background-color: transparent;
    box-shadow: none;
    top: 32px;
  }
}
section {
  padding-top: 132px;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100vh;
  width: 100vw;
  place-content: center;
  align-items: center;
  overflow-x: hidden;
}

#home {
  filter: brightness(95%);
  overflow: hidden;
  background: linear-gradient(180deg, hsl(222deg, 66%, 59%) 0%, hsl(293deg, 63%, 79%) 62%, hsl(25deg, 84%, 71%) 100%);
}

.containerH {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr;
  grid-auto-columns: 1fr;
  grid-auto-flow: row dense;
  justify-content: center;
  align-content: start;
  justify-items: stretch;
  align-items: stretch;
  grid-template-areas: "text1 logoc text2";
  width: 86%;
  height: 100%;
  margin: auto 10%;
  padding: 0;
  gap: 0 1%;
}

.text1 {
  grid-area: text1;
  color: white;
  width: 100%;
  align-self: start;
  opacity: 0;
}

.text1 h1,
.jointext1 h2,
.jointext2 h2,
.faqtitle h2,
.jointext1 p,
.jointext2 p {
  color: white;
}

h1 {
  font-family: NoirPro-Bold;
  font-size: calc(12px + 24 * (100vw - 320px) / 680);
  line-height: calc(12px + 34 * (100vw - 320px) / 680);
  font-weight: 700;
  text-transform: uppercase;
}

h2 {
  font-family: NoirPro-Bold;
  font-size: calc(12px + 24 * (100vw - 320px) / 680);
  line-height: calc(12px + 34 * (100vw - 320px) / 680);
  font-weight: 700;
  text-transform: uppercase;
}

.Hbold {
  font-family: NoirPro-Bold;
  font-size: clamp(0.4rem, 1vw, 1.1rem);
  line-height: clamp(0.5rem, 1.5vw, 2.5rem);
  font-weight: 700;
  text-transform: uppercase;
}

.Hboldi {
  font-family: NoirPro-BoldItalic;
  font-size: clamp(0.4rem, 1vw, 1.1rem);
  line-height: clamp(0.5rem, 1.5vw, 2.5rem);
  font-weight: 700;
}

.Hp {
  font-family: NoirPro-Regular;
  font-size: clamp(0.4rem, 0.95vw, 1.12rem);
  line-height: clamp(0.4rem, 1.5vw, 2.5rem);
  font-weight: 400;
}

.list {
  font-family: NoirPro-Regular;
  font-size: clamp(0.5rem, 0.8vw, 1rem);
  line-height: clamp(0.4rem, 1.5vw, 2.5rem);
  font-weight: 400;
  color: #161616;
}

.proclinks .Mp {
  font-family: NoirPro-Medium;
  font-size: clamp(0.5rem, 1.1vw, 1.2rem);
  line-height: clamp(0.5rem, 1.5vw, 2.5rem);
  text-decoration-line: underline;
  text-transform: uppercase;
  font-weight: 500;
  color: #161616;
  list-style-type: circle;
}

.Sp {
  font-family: NoirPro-SemiBold;
  font-size: clamp(0.5rem, 1.2vw, 1.3rem);
  line-height: clamp(0.4rem, 1.5vw, 2.5rem);
  font-weight: 600;
}

.logoc {
  grid-area: logoc;
  opacity: 1;
}

.logoc .img1 {
  position: relative;
  top: -12%;
  width: 100%;
  height: auto;
  opacity: 0;
  aspect-ratio: 1.8641509434/1;
}

.logoc .img2 {
  width: 30%;
  right: 35%;
  height: auto;
  position: absolute;
  opacity: 0;
  filter: brightness(85%);
  z-index: -1;
}

.text2 {
  grid-area: text2;
  color: white;
  width: 100%;
  opacity: 0;
  align-self: start;
  margin-top: 12%;
}

.text2 svg {
  transform: translateY(100%);
  width: 55%;
  cursor: pointer;
  transition: 0.6s;
  stroke: #fff;
  fill: #fff;
}

.text2 svg:hover {
  fill: aqua;
  stroke: aqua;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #home {
    padding-top: 84px;
  }
  section {
    padding-top: 0;
    align-items: center;
  }
  .containerH {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.45fr 1fr;
    justify-content: start;
    align-content: start;
    justify-items: start;
    align-items: start;
    grid-template-areas: "text1" "logoc" "text2";
    width: 95vw;
    margin: 10% 0 20% 0;
    gap: 0 0;
    height: 100%;
  }
  .logoc .img1 {
    top: 0;
    margin-top: 0;
  }
  h1 {
    font-size: clamp(2.8rem, 4vw, 4.1rem);
    line-height: clamp(3rem, 4vw, 4.5rem);
  }
  h2 {
    font-size: clamp(2.8rem, 4vw, 4.1rem);
    line-height: clamp(3rem, 4vw, 4.5rem);
  }
  .Hbold {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }
  .Hp {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }
  .text1 {
    max-inline-size: 100%;
  }
  .img2 {
    display: none;
  }
  .text2 svg {
    padding: 0;
    margin-bottom: 0;
  }
  .text2 {
    padding-top: 0;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  section {
    min-height: calc(var(--vh, 1vh) * 94);
    align-items: center;
    justify-content: flex-start;
  }
  .containerH {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 0.8fr 1fr;
    justify-content: start;
    align-content: start;
    justify-items: start;
    align-items: start;
    grid-template-areas: "text1" "logoc" "text2";
    width: 95vw;
    margin: 0;
    padding: 0 5%;
    gap: 0;
    height: 100%;
  }
  .logoc {
    align-self: center;
  }
  .logoc .img1 {
    top: 0;
    margin-top: 55px;
    align-items: center;
    align-content: center;
  }
  h1 {
    font-size: clamp(5rem, 4vw, 4.1rem);
    line-height: clamp(5rem, 4vw, 4.5rem);
  }
  h2 {
    font-size: clamp(5rem, 4vw, 4.1rem);
    line-height: clamp(5rem, 4vw, 4.5rem);
  }
  .Hbold {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.5rem, 1.5vw, 2.5rem);
  }
  .Hp {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.7rem, 2vw, 2.5rem);
  }
  .text1 {
    max-inline-size: 60%;
  }
  .img2 {
    display: none;
  }
  .text2 svg {
    padding: 0;
    margin-bottom: 0;
    width: 30%;
  }
  .text2 {
    padding-top: 0;
    margin-top: 0;
    align-self: start;
    align-items: start;
    align-content: start;
  }
}
#process {
  width: 100vw;
  background-color: #fff;
  box-shadow: 1px 5px 9px -4px rgba(0, 0, 0, 0.72) inset;
  -webkit-box-shadow: 1px 5px 9px -4px rgba(0, 0, 0, 0.72) inset;
  -moz-box-shadow: 1px 5px 9px -4px rgba(0, 0, 0, 0.72) inset;
}

.containerproc1 {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0;
  grid-template-areas: "proctext procimg";
  width: 86%;
  margin: auto 10%;
  padding: 0;
  z-index: 1;
  overflow: visible;
}

.proctext {
  grid-area: proctext;
  padding-top: 20%;
  align-self: flex-start;
  height: fit-content;
  color: #161616;
}

h1 {
  color: #161616;
}

h2 {
  color: #161616;
}

.procimg1 {
  grid-area: procimg;
  overflow: visible;
}

.procimg1 img {
  grid-area: procimg;
  position: relative;
  max-width: 1123px;
  width: 150%;
  height: auto;
  top: -20%;
  left: 10%;
  aspect-ratio: 1123/1095;
}

.containerproc2 {
  background: linear-gradient(180deg, #40e0d0 0%, rgba(64, 224, 208, 0.05) 95.96%);
  border-radius: 100px 100px 0px 0px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 0.2fr 0.35fr 0.1fr 0.1fr 0.6fr 0.3fr 0.5fr 0.4fr;
  gap: 0;
  grid-auto-flow: row;
  grid-template-areas: "proctitle1 proctitle1 proctitle1 proctitle1" "procicon procicon procicon procicon" "proclinktitle proclinktitle proclinktitle proclinktitle" "proclinks proclinks proclinks proclinks" "procimg procimg procimgtitle procimgtitle" "prochowtitle prochowtitle prochowtext prochowtext" "howimg1 howimg2 howimg3 howimg4" "procdiff procdiff procdiff procdiff";
  width: 86%;
  height: 100%;
  margin-top: -20%;
  padding-top: 5%;
  padding-left: 4%;
  padding-right: 4%;
  z-index: 0;
}

.proctitle1 {
  grid-area: proctitle1;
}

.proctitle1 p {
  white-space: nowrap;
}

.procicon {
  height: auto;
  flex-flow: row;
  grid-area: procicon;
  justify-content: space-between;
  display: flex;
}

.procicon div {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  width: 100%;
}

.procicon div img {
  max-width: 380px;
  width: 100%;
  height: auto;
  aspect-ratio: 1.5802469136/1;
}

.proclinktitle {
  grid-area: proclinktitle;
  white-space: nowrap;
  padding-top: 20px;
  padding-bottom: 20px;
}

.proclinks {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-area: proclinks;
  padding-bottom: 6%;
  gap: 5%;
}

.proclink1 {
  max-width: 282px;
  width: 84%;
  height: auto;
}

.proclink1 img {
  width: 100%;
  height: auto;
  aspect-ratio: 127/10;
}

.proclink2 {
  display: flex;
  flex-flow: column wrap;
  max-width: 340px;
  width: 100%;
  height: auto;
  gap: 80%;
}

.proclink2 img {
  width: 100%;
  height: auto;
  aspect-ratio: 302/19;
}

.proclink2b img {
  width: 100%;
  height: auto;
  margin-top: 10%;
  aspect-ratio: 302/19;
}

.proclink3 {
  max-width: 254px;
  width: 76%;
  height: auto;
}

.proclink3 img {
  width: 100%;
  height: auto;
  aspect-ratio: 229/20;
}

.proclink4 {
  max-width: 179px;
  width: 55%;
  height: auto;
}

.proclink4 img {
  width: 100%;
  height: auto;
  aspect-ratio: 83/10;
}

.proclink5 {
  max-width: 269px;
  width: 82%;
  height: auto;
}

.proclink5 img {
  width: 100%;
  height: auto;
  aspect-ratio: 247/20;
}

.proclink5:hover,
.proclink4:hover,
.proclink3:hover,
.proclink1:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.proclink2a:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.proclink2b:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.procimg2 {
  grid-area: procimg;
  max-width: 735px;
  width: 100%;
  height: auto;
}

.procimg2 img {
  width: 98%;
  height: auto;
  aspect-ratio: 735/801;
}

.procimgtitle {
  grid-area: procimgtitle;
  align-self: center;
  padding-left: 4%;
}

.procimgtitle svg {
  transform: translateY(100%);
  width: 25%;
  cursor: pointer;
  transition: 0.6s;
  stroke: rgb(0, 0, 0);
  fill: rgb(0, 0, 0);
}

.procimgtitle svg:hover {
  fill: rgb(255, 255, 255);
  stroke: rgb(255, 255, 255);
}

.prochowtitle {
  grid-area: prochowtitle;
  align-self: center;
  padding: 12% 0 10% 0;
}

.prochowtext {
  grid-area: prochowtext;
  align-self: center;
  padding: 12% 0 10% 4%;
}

.howimg1 {
  grid-area: howimg1;
  width: 100%;
  padding-left: 7%;
  height: auto;
}

.howimg2 {
  grid-area: howimg2;
  padding-left: 7%;
  width: 100%;
  height: auto;
}

.howimg3 {
  grid-area: howimg3;
  padding-left: 7%;
  width: 100%;
  height: auto;
}

.howimg4 {
  grid-area: howimg4;
  padding-left: 7%;
  width: 100%;
  height: auto;
}

.procdiff {
  grid-area: procdiff;
  background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 56.59%), linear-gradient(180deg, rgba(58, 62, 157, 0.25) 23.25%, rgba(64, 224, 208, 0.45) 100%);
  border: 4px solid #40e0d0;
  border-radius: 20px;
  align-self: center;
  padding: 2%;
  margin: 8% 0 0 0;
  color: #5359e0;
}

.procdiff h1 {
  color: #5359e0;
}

.procdiff h2 {
  color: #5359e0;
}

.procdifflinkc {
  display: flex;
  flex-direction: row;
  white-space: normal;
  max-inline-size: 100%;
  width: 100%;
  justify-content: space-between;
}

.procdifflinkc p {
  max-inline-size: 100%;
}

.procdifflink {
  position: relative;
  max-width: 180px;
  width: 18%;
  height: auto;
  align-self: center;
}

.procdifflink img {
  width: 100%;
  height: auto;
  aspect-ratio: 172/21;
}

.procdifflink:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.card1 {
  display: block;
  max-width: 335px;
  width: 100%;
  height: 88%;
  border-radius: 5%;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}

.card1 img,
.card2 img,
.card3 img,
.card4 img {
  width: 100%;
  height: auto;
  clip-path: inset(0 1% 0 1%);
  aspect-ratio: 0.8678756477/1;
}

.card2 {
  display: block;
  max-width: 335px;
  width: 100%;
  height: 88%;
  border-radius: 5%;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}

.card3 {
  display: block;
  max-width: 335px;
  width: 100%;
  height: 88%;
  border-radius: 5%;
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;
}

.card4 {
  display: block;
  max-width: 335px;
  width: 100%;
  height: 88%;
  border-radius: 5%;
  background-color: #fff;
  margin: 0 auto;
  overflow: hidden;
  cursor: pointer;
}

@media screen and (pointer: fine) {
  .card1:hover,
.card2:hover,
.card3:hover,
.card4:hover {
    animation: pulse 1.5s var(--ease-5);
  }
}
.Ctitle {
  margin-top: 10%;
  text-align: center;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .containerproc1 {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: "proctext";
    width: 95vw;
    margin: 10% 0 10% 0;
  }
  .procimg1 {
    display: none;
  }
  .proctext {
    padding-top: 0;
  }
  .containerproc2 {
    border-radius: 50px 50px 0px 0px;
    grid-template-rows: 0.1fr 0.1fr 0.1fr 0.1fr 0.6fr 0.1fr 0.12fr 0.5fr 0.5fr 0.5fr 0.5fr 0.35fr;
    grid-template-areas: "proctitle1 proctitle1 proctitle1 proctitle1" "procicon procicon procicon procicon" "proclinktitle proclinktitle proclinktitle proclinktitle" "proclinks proclinks proclinks proclinks" "procimgtitle procimgtitle procimgtitle procimgtitle" "prochowtitle prochowtitle prochowtitle prochowtitle" "prochowtext prochowtext prochowtext prochowtext" "howimg1 howimg1 howimg1 howimg1" "howimg2 howimg2 howimg2 howimg2" "howimg3 howimg3 howimg3 howimg3" "howimg4 howimg4 howimg4 howimg4" "procdiff procdiff procdiff procdiff";
    width: 100vw;
    margin-top: 0;
    padding-top: 10%;
  }
  .proctitle1 p {
    white-space: normal;
  }
  .procimg2 {
    display: none;
  }
  .procicon {
    flex-flow: column wrap;
  }
  .procicon div {
    flex-flow: column wrap;
    width: 100%;
  }
  .procicon div img {
    min-width: 250px;
    padding-bottom: 15px;
  }
  .proclinks {
    flex-wrap: wrap;
    padding-bottom: 30px;
    padding-top: 30px;
    gap: 0;
  }
  .proclink1 {
    width: 100%;
    padding-bottom: 34px;
  }
  .proclink2 {
    display: block;
    position: relative;
    left: -2px;
    padding-bottom: 34px;
  }
  .proclink3 {
    width: 100%;
    padding-bottom: 34px;
  }
  .proclink4 {
    width: 100%;
    padding-bottom: 34px;
  }
  .proclink5 {
    width: 100%;
  }
  .procimgtitle {
    padding-left: 0;
  }
  .Hboldi {
    font-size: clamp(1rem, 2vw, 2.1rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }
  .prochowtitle {
    padding-top: 80px;
    padding-bottom: 10px;
  }
  .prochowtext {
    padding-left: 0;
    padding-bottom: 40px;
  }
  .procimgtitle svg {
    width: 40%;
  }
  .Sp {
    font-size: clamp(1rem, 1.2vw, 1.3rem);
    line-height: clamp(1rem, 1.5vw, 2.5rem);
  }
  .howimg1 {
    padding-left: 0;
    padding-bottom: 20px;
  }
  .howimg2 {
    padding-left: 0;
    padding-bottom: 20px;
  }
  .howimg3 {
    padding-left: 0;
    padding-bottom: 20px;
  }
  .howimg4 {
    padding-left: 0;
    padding-bottom: 20px;
  }
  .card1,
.card2 {
    float: none;
    margin: 0 auto;
  }
  .card1 img,
.card2 img,
.card3 img,
.card4 img {
    width: 100%;
    height: auto;
  }
  .card4,
.card3 {
    float: none;
    margin: 0 auto;
  }
  .procdiff {
    padding: 4%;
    margin-bottom: 20px;
  }
  .procdifflinkc {
    flex-direction: column;
    white-space: normal;
  }
  .procdifflink {
    width: 100%;
    align-self: flex-start;
    padding-top: 10px;
  }
  .proclinktitle {
    white-space: normal;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #process {
    padding-top: 0;
  }
  .containerproc1 {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    grid-template-areas: "proctext procimg";
    width: 86%;
    margin: auto 10%;
    padding: 0;
    z-index: 1;
    overflow: visible;
  }
  .proctext {
    grid-area: proctext;
    padding-top: 20%;
    align-self: flex-start;
    height: fit-content;
  }
  .procimg1 {
    grid-area: procimg;
    overflow: visible;
  }
  .procimg1 img {
    position: relative;
    max-width: 1123px;
    width: 150%;
    height: auto;
    top: 10%;
    left: 10%;
  }
  .containerproc2 {
    background: linear-gradient(180deg, #40e0d0 0%, rgba(64, 224, 208, 0.05) 95.96%);
    border-radius: 100px 100px 0px 0px;
    position: relative;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: 0.2fr 0.35fr 0.1fr 0.1fr 0.6fr 0.3fr 0.5fr 0.4fr;
    gap: 0;
    grid-auto-flow: row;
    grid-template-areas: "proctitle1 proctitle1 proctitle1 proctitle1" "procicon procicon procicon procicon" "proclinktitle proclinktitle proclinktitle proclinktitle" "proclinks proclinks proclinks proclinks" "procimg procimgtitle procimgtitle procimgtitle" "prochowtitle prochowtitle prochowtext prochowtext" "howimg1 howimg2 howimg3 howimg4" "procdiff procdiff procdiff procdiff";
    width: 86%;
    height: 100%;
    margin-top: 80px;
    padding-top: 5%;
    padding-left: 4%;
    padding-right: 4%;
    z-index: 0;
  }
  .proctitle1 {
    grid-area: proctitle1;
  }
  .proctitle1 p {
    white-space: normal;
  }
  .procicon {
    height: auto;
    flex-flow: row;
    grid-area: procicon;
    justify-content: space-between;
    display: flex;
  }
  .procicon div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    width: 100%;
  }
  .procicon div img {
    max-width: 380px;
    width: 100%;
    height: auto;
  }
  .proclinktitle {
    grid-area: proclinktitle;
    white-space: normal;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .proclinks {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    grid-area: proclinks;
    padding-bottom: 6%;
    gap: 5%;
  }
  .proclink1 {
    max-width: 282px;
    width: 84%;
    height: auto;
  }
  .proclink1 img {
    width: 100%;
    height: auto;
  }
  .proclink2 {
    display: flex;
    flex-flow: column wrap;
    max-width: 340px;
    width: 100%;
    height: auto;
    gap: 80%;
  }
  .proclink2 img {
    width: 100%;
    height: auto;
  }
  .proclink2b img {
    width: 100%;
    height: auto;
    margin-top: 10%;
  }
  .proclink3 {
    max-width: 254px;
    width: 76%;
    height: auto;
  }
  .proclink3 img {
    width: 100%;
    height: auto;
  }
  .proclink4 {
    max-width: 179px;
    width: 55%;
    height: auto;
  }
  .proclink4 img {
    width: 100%;
    height: auto;
  }
  .proclink5 {
    max-width: 269px;
    width: 82%;
    height: auto;
  }
  .proclink5 img {
    width: 100%;
    height: auto;
  }
  .proclink5:hover,
.proclink4:hover,
.proclink3:hover,
.proclink1:hover {
    animation: shake-x 1.5s var(--ease-5);
  }
  .proclink2a:hover {
    animation: shake-x 1.5s var(--ease-5);
  }
  .proclink2b:hover {
    animation: shake-x 1.5s var(--ease-5);
  }
  .procimg2 {
    grid-area: procimg;
    max-width: 735px;
    width: 100%;
    height: auto;
  }
  .procimg2 img {
    width: 100%;
    height: auto;
  }
  .procimgtitle {
    grid-area: procimgtitle;
    align-self: center;
    padding-left: 4%;
  }
  .procimgtitle svg {
    transform: translateY(100%);
    width: 20%;
    cursor: pointer;
    transition: 0.6s;
    stroke: rgb(0, 0, 0);
    fill: rgb(0, 0, 0);
  }
  .procimgtitle svg:hover {
    fill: rgb(255, 255, 255);
    stroke: rgb(255, 255, 255);
  }
  .prochowtitle {
    grid-area: prochowtitle;
    align-self: center;
    padding: 12% 0 10% 0;
  }
  .prochowtext {
    grid-area: prochowtext;
    align-self: center;
    padding: 12% 0 10% 4%;
  }
  .howimg1 {
    grid-area: howimg1;
    width: 100%;
    padding-left: 7%;
    height: auto;
  }
  .howimg2 {
    grid-area: howimg2;
    padding-left: 7%;
    width: 100%;
    height: auto;
  }
  .howimg3 {
    grid-area: howimg3;
    padding-left: 7%;
    width: 100%;
    height: auto;
  }
  .howimg4 {
    grid-area: howimg4;
    padding-left: 7%;
    width: 100%;
    height: auto;
  }
  .procdiff {
    grid-area: procdiff;
    background: linear-gradient(270deg, rgba(255, 255, 255, 0.2) 0%, rgba(0, 0, 0, 0) 56.59%), linear-gradient(180deg, rgba(58, 62, 157, 0.25) 23.25%, rgba(64, 224, 208, 0.45) 100%);
    border: 4px solid #40e0d0;
    border-radius: 20px;
    align-self: center;
    padding: 2%;
    margin: 8% 0 0 0;
  }
  .procdifflinkc {
    display: flex;
    flex-direction: row;
    white-space: normal;
    max-inline-size: 100%;
    width: 100%;
    justify-content: space-between;
  }
  .procdifflinkc p {
    max-inline-size: 70%;
  }
  .procdifflink {
    position: relative;
    max-width: 180px;
    width: 25%;
    height: auto;
    align-self: center;
  }
  .procdifflink img {
    width: 100%;
    height: auto;
  }
  .procdifflink:hover {
    animation: shake-x 1.5s var(--ease-5);
  }
  .card1 {
    display: block;
    max-width: 335px;
    width: 100%;
    height: 88%;
    border-radius: 5%;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
  }
  .card1 img,
.card2 img,
.card3 img,
.card4 img {
    width: 100%;
    height: auto;
  }
  .card2 {
    display: block;
    max-width: 335px;
    width: 100%;
    height: 88%;
    border-radius: 5%;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
  }
  .card3 {
    display: block;
    max-width: 335px;
    width: 100%;
    height: 88%;
    border-radius: 5%;
    background-color: #fff;
    overflow: hidden;
    cursor: pointer;
  }
  .card4 {
    display: block;
    max-width: 335px;
    width: 100%;
    height: 88%;
    border-radius: 5%;
    background-color: #fff;
    margin: 0 auto;
    overflow: hidden;
    cursor: pointer;
  }
  .card1:hover,
.card2:hover,
.card3:hover,
.card4:hover {
    animation: pulse 1.5s var(--ease-5);
  }
  .Ctitle {
    margin-top: 10%;
    text-align: center;
  }
}
#joinus {
  padding: 7%;
  border: 1px solid #000000;
  background-image: url(/static/joinusback.webp);
  background-size: cover;
  background-position: right;
}

.joincontainer {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);
  gap: 15% 0;
  grid-auto-flow: row;
  justify-content: start;
  align-content: start;
  justify-items: start;
  align-items: start;
  grid-template-areas: "jointext1" "jointext2";
  width: 100%;
  height: 100%;
}

.jointext1 {
  justify-self: start;
  align-self: start;
  grid-area: jointext1;
}

.joinlink {
  max-width: 200px;
  width: 45%;
  height: auto;
}

.joinlink:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.joinlink img {
  width: 100%;
  height: 100%;
  aspect-ratio: 196/25;
}

.jointext2 {
  justify-self: start;
  align-self: start;
  grid-area: jointext2;
  background: linear-gradient(0deg, rgba(58, 62, 157, 0.45) 23.25%, rgba(64, 224, 208, 0.25) 100%);
  border: 4px solid #535ce0;
  border-radius: 20px;
  padding: 2%;
}

.jointext2 p {
  max-inline-size: 100%;
}

.joinlink2 {
  max-width: 264px;
  width: 30%;
  height: auto;
}

.joinlink2:hover {
  animation: shake-x 1.5s var(--ease-5);
}

.joinlink2 img {
  width: 100%;
  height: 100%;
  aspect-ratio: 88/9;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #joinus {
    background-position: left;
  }
  .joincontainer {
    grid-template-rows: 0.95fr 1fr;
    width: 90vw;
  }
  .joinlink {
    width: 50%;
  }
  .joinlink2 {
    width: 70%;
    padding-bottom: 20px;
  }
  .jointext2 {
    padding: 2%;
    width: 90vw;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .joincontainer {
    grid-template-rows: 0.6fr 1fr;
    width: 90vw;
    padding-top: 100px;
    gap: 0;
  }
  .joinlink2 {
    width: 70%;
    padding-bottom: 0;
  }
}
#team {
  background-color: white;
  background-image: url(/static/team_back.webp);
  background-size: cover;
  background-position: right;
  color: #161616;
}

.teamcontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 0.1fr 1fr 0.6fr;
  gap: 1% 3%;
  grid-auto-flow: row;
  grid-template-areas: "teamtitle teamtitle teamtitle" "team1img team2img team3img" "team1text teamt2text team3text";
  width: 86%;
  height: 100%;
}

.teamtitle {
  grid-area: teamtitle;
  justify-self: center;
  align-self: flex-start;
}

.team1img {
  grid-area: team1img;
  justify-self: center;
  align-self: center;
  max-width: 469px;
  width: 100%;
  height: auto;
}

.team1img img,
.team2img img,
.team3img img {
  width: 100%;
  height: auto;
  aspect-ratio: 1/1;
}

.team2img {
  grid-area: team2img;
  justify-self: center;
  align-self: center;
}

.team3img {
  grid-area: team3img;
  justify-self: center;
  align-self: center;
}

.team1text {
  grid-area: team1text;
  justify-self: center;
  align-self: start;
  padding: 0 5% 0 5%;
}

.teamt2text {
  grid-area: teamt2text;
  justify-self: center;
  align-self: start;
  padding: 0 5% 0 5%;
}

.team3text {
  grid-area: team3text;
  justify-self: center;
  align-self: start;
  padding: 0 5% 0 5%;
}

.teamname {
  font-family: NoirPro-Bold;
  font-size: clamp(0.5rem, 1.1vw, 1.2rem);
  line-height: clamp(0.5rem, 1.5vw, 2.5rem);
  font-weight: 700;
  text-transform: uppercase;
}

.teamposition {
  font-family: NoirPro-SemiBold;
  font-size: clamp(0.4rem, 1.1vw, 1.2rem);
  line-height: clamp(0.4rem, 1.5vw, 2.5rem);
  font-weight: 600;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #team {
    padding-bottom: 60px;
  }
  .teamcontainer {
    grid-template-rows: 0.2fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr;
    gap: 1%;
    grid-template-areas: "teamtitle teamtitle teamtitle" "team1img team1img team1img" "team1text team1text team1text" "team2img team2img team2img" "teamt2text teamt2text teamt2text" "team3img team3img team3img" "team3text team3text team3text";
    width: 100vw;
    padding: 10% 4%;
  }
  .team1text {
    padding: 0 5% 5% 5%;
  }
  .teamt2text {
    padding: 0 5% 5% 5%;
  }
  .team3text {
    padding: 0 5% 0 5%;
  }
  .teamname {
    font-size: clamp(1.2rem, 1.1vw, 1.2rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }
  .teamposition {
    font-size: clamp(1rem, 1.1vw, 1.2rem);
    line-height: clamp(1.2rem, 1.5vw, 2.5rem);
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #team {
    padding-bottom: 60px;
  }
  .teamcontainer {
    grid-template-rows: 0.2fr 1fr 0.5fr 1fr 0.5fr 1fr 0.5fr;
    gap: 1%;
    grid-template-areas: "teamtitle teamtitle teamtitle" "team1img team1img team1img" "team1text team1text team1text" "team2img team2img team2img" "teamt2text teamt2text teamt2text" "team3img team3img team3img" "team3text team3text team3text";
    width: 100vw;
    padding: 0 4%;
  }
  .team1text {
    padding: 0 5% 5% 5%;
    text-align: center;
  }
  .teamt2text {
    padding: 0 5% 5% 5%;
    text-align: center;
  }
  .team3text {
    padding: 0 5% 0 5%;
    text-align: center;
  }
  .teamname {
    font-size: clamp(2rem, 1.1vw, 1.2rem);
    line-height: clamp(2.2rem, 1.5vw, 2.5rem);
  }
  .teamposition {
    font-size: clamp(1.6rem, 1.1vw, 1.2rem);
    line-height: clamp(1.9rem, 1.5vw, 2.5rem);
  }
}
#info {
  background-color: #fff;
}

.faqcontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 0.1fr 0.1fr;
  gap: 3% 3%;
  grid-auto-flow: row;
  grid-template-areas: "faqtitle faqtitle faqtitle" "faq1 faq2 faq3";
  background: linear-gradient(180deg, #5359e0 0%, rgba(83, 89, 224, 0.05) 95.96%);
  border-radius: 50px 50px 0 0;
  width: 86%;
  height: 100%;
  padding: 3% 3% 6% 3%;
}

.faqtitle {
  justify-self: center;
  align-self: flex-start;
  grid-area: faqtitle;
  padding-bottom: 3%;
}

.faq1 {
  justify-self: center;
  align-self: center;
  grid-area: faq1;
}

.faq2 {
  justify-self: center;
  align-self: center;
  grid-area: faq2;
}

.faq3 {
  justify-self: center;
  align-self: center;
  grid-area: faq3;
}

.faq11container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1% 1%;
  grid-auto-flow: row;
  grid-template-areas: "toptext toptext" "list1 list2";
}

.toptext {
  justify-self: start;
  align-self: center;
  grid-area: toptext;
}

.list1 {
  justify-self: center;
  grid-area: list1;
}

.list2 {
  justify-self: center;
  grid-area: list2;
}

.accordion-header {
  background-color: rgb(255, 255, 255);
  cursor: pointer;
  color: #181818;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  transition: all 0.4s ease-in-out;
  border-radius: 4px;
}

.accordion-header:hover {
  animation: blink 1s var(--ease-5);
}

.accordion-header h3 {
  font-size: clamp(0.5rem, 0.8vw, 1rem);
  font-weight: 600;
  color: #181818;
  max-inline-size: 100%;
}

.accordion-body {
  transition: all 0.35s ease-in-out;
  max-height: 0;
  overflow: hidden;
  background-color: rgb(252, 252, 252);
  border-radius: 0 0 4px 4px;
}

.accordion-body p {
  font-size: clamp(0.5rem, 0.8vw, 1rem);
  font-weight: 600;
  padding: 1rem 1.5rem;
  line-height: 1.8;
  color: #181818;
}

.accordion + .accordion {
  margin-top: 1rem;
}

.accordion-icon {
  transition: transform 0.4s ease-in-out;
}

.accordion.active .accordion-header {
  background-color: white;
  border-radius: 4px 4px 0 0;
}

.accordion.active .accordion-icon {
  transform: rotate(-180deg);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  #info {
    padding-top: 50px;
  }
  .faqcontainer {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 60px 1fr;
    gap: 15px 0;
    grid-template-areas: "faqtitle" "faq1" "faq2" "faq3";
    border-radius: 50px 50px 0 0;
    width: 100%;
    padding: 10% 4% 6% 4%;
  }
  .list1 {
    padding-left: 1.5rem;
  }
  .list2 {
    padding-left: 1.5rem;
  }
  .faqtitle {
    padding-bottom: 0;
  }
  .accordion-header h3 {
    font-size: clamp(1rem, 0.8vw, 1rem);
  }
  .accordion-body p {
    font-size: clamp(1rem, 0.8vw, 1rem);
    padding: 1rem 0.5rem;
    line-height: 1.5;
  }
  .list {
    font-size: clamp(1rem, 0.8vw, 1rem);
    line-height: clamp(1.4rem, 1.5vw, 2.5rem);
    padding: 0 0.5rem;
  }
  .accordion-header {
    width: 100%;
    padding: 1rem 0.4rem;
  }
  .faq1,
.faq2,
.faq3 {
    width: 100%;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  #info {
    padding-top: 50px;
  }
  .faqcontainer {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 160px 1fr;
    gap: 15px 0;
    grid-template-areas: "faqtitle" "faq1" "faq2" "faq3";
    border-radius: 50px 50px 0 0;
    width: 90%;
    padding: 10% 4% 6% 4%;
  }
  .list1 {
    padding-left: 1.5rem;
  }
  .list2 {
    padding-left: 1.5rem;
  }
  .faqtitle {
    padding-bottom: 0;
  }
  .accordion-header h3 {
    font-size: clamp(1rem, 0.8vw, 1rem);
    max-inline-size: 100%;
  }
  .accordion-body p {
    font-size: clamp(1rem, 0.8vw, 1rem);
    padding: 1rem 0.5rem;
    line-height: 1.5;
  }
  .list {
    font-size: clamp(1rem, 0.8vw, 1rem);
    line-height: clamp(1.4rem, 1.5vw, 2.5rem);
    padding: 0 0.5rem;
  }
  .accordion-header {
    width: 100%;
    padding: 1rem 1rem;
  }
  .faq1,
.faq2,
.faq3 {
    width: 80%;
  }
}
.footer {
  width: 100vw;
  height: 200px;
  background: linear-gradient(180deg, hsl(222deg, 66%, 59%) 0%, hsl(293deg, 63%, 79%) 100%);
  box-shadow: inset 0px 5 10px -4px rgba(0, 0, 0, 0.8);
}

.footover {
  background: linear-gradient(89.97deg, rgba(32, 35, 38, 0) 0.02%, rgba(32, 35, 38, 0.5) 99.96%);
  height: 100%;
  width: 100vw;
}

.footcont {
  width: 80%;
  height: 100%;
  margin-left: clamp(2rem, 15rem, 10%);
  margin-right: clamp(2rem, 15rem, 10%);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.footpad {
  padding-right: 10%;
}

.footimg1 {
  max-width: 123px;
  width: 100%;
  height: auto;
  aspect-ratio: 123/19;
}

.footimg2 {
  max-width: 138px;
  width: 100%;
  height: auto;
  aspect-ratio: 137/22;
}

.footimg3 {
  max-width: 181px;
  width: 100%;
  height: auto;
  aspect-ratio: 181/22;
}

.footimg4 {
  max-width: 186px;
  width: 100%;
  height: auto;
  aspect-ratio: 1.8641509434/1;
}

.footimg5 {
  max-width: 271px;
  width: 100%;
  height: auto;
  aspect-ratio: 271/22;
}

.footlast {
  padding-right: 0;
}

.footcont:nth-child(1) img:hover {
  animation: shake-x 1.5s var(--ease-5);
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .footer {
    width: 100%;
    height: 430px;
  }
  .footcont {
    height: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px 0;
    padding-top: 40px;
  }
  .footpad {
    padding-right: 0;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .footer {
    width: 100%;
    height: 230px;
  }
  .footcont {
    height: 80%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px 0;
    padding-top: 40px;
  }
  .footpad {
    padding-right: 30px;
  }
}
.modal__close {
  position: absolute;
  right: 1em;
  top: 1em;
  width: 1.1em;
  height: 1.1em;
  cursor: pointer;
  z-index: 1;
}

.modal__close:after,
.modal__close:before {
  content: "";
  position: absolute;
  border-radius: 40px;
  width: 4px;
  height: 1.5em;
  background: rgb(60, 80, 255);
  display: block;
  transform: rotate(45deg);
  left: 50%;
  margin: -3px 0 0 -1px;
  top: 0;
}

.modal__close:hover:after,
.modal__close:hover:before {
  background: rgb(255, 83, 83);
}

.modal__close:before {
  transform: rotate(-45deg);
}

.modal-wrapper1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(93, 0, 130, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
}

.modal-wrapper2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(26, 0, 170, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
}

.modal-wrapper3 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(170, 0, 162, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
}

.modal-wrapper4 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(170, 111, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  transform: translateY(100%);
  clip-path: circle(0% at 0 0);
  z-index: 300;
  backdrop-filter: blur(10px);
  will-change: clip-path;
}

.modal {
  border-radius: 12px;
  background: rgb(255, 255, 255);
  width: 70vw;
  min-height: 330px;
  height: fit-content;
  padding: 50px 24px 24px 24px;
  transform: translateY(20%);
  opacity: 0;
  overflow-y: auto;
  box-shadow: var(--shadow-3);
  will-change: opacity, transform;
}

.modal__title {
  position: absolute;
  top: 20px;
  left: 20px;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.25;
  color: #00449e;
  box-sizing: border-box;
}

.modal__content {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;
  line-height: 1.5;
  color: rgba(0, 0, 0, 0.8);
}

.modaltext {
  justify-self: center;
  align-self: stretch;
  grid-area: modaltext;
  z-index: 1;
}

.modaltext p {
  max-inline-size: 70%;
}

.modalimg {
  max-width: 280px;
  width: 35%;
  height: auto;
  position: absolute;
  right: 10px;
  bottom: 0;
  z-index: 0;
  clip-path: inset(0 1% 0 1%);
  aspect-ratio: 0.8678756477/1;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .modalimg {
    display: none;
  }
  .modal__content {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-template-areas: "modaltext";
  }
  .modal {
    width: 90vw;
  }
  .modaltext p {
    max-inline-size: 90%;
  }
}
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .modal__content {
    display: grid;
    grid-template-columns: 1fr 0.5fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-template-areas: "modaltext modalimg";
  }
  .modal {
    width: 90vw;
  }
  .modaltext p {
    max-inline-size: 90%;
  }
}
@media (pointer: fine) {
  .cursor .cursor--small,
.cursor .cursor--large,
.cursor .cursor--text {
    position: fixed;
    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: var(--cursor-size);
    height: var(--cursor-size);
    mix-blend-mode: difference;
    pointer-events: none;
    user-select: none;
    z-index: 301;
  }
  .cursor .cursor--text {
    --cursor-size: fit-content;
    font-size: 0.75rem;
    color: #fff;
    opacity: 0;
  }
  .cursor .cursor--text .text {
    font-family: NoirPro-Medium;
  }
  .cursor .cursor--small {
    --cursor-size: 20px;
    background: #fff;
  }
  .cursor .cursor--large {
    --cursor-size: 60px;
    border: 2px solid #fff;
  }
}
@media (pointer: coarse) {
  .cursor {
    display: none;
  }
  .cursor--text {
    display: none;
  }
  .text {
    display: none;
  }
  .cursor--small {
    display: none;
  }
  .cursor--large {
    display: none;
  }
}
.titletesti {
  padding-left: 4%;
  padding-right: 4%;
}

.splide {
  width: 92%;
  position: relative;
  margin-bottom: 20px;
}

.splide::before {
  content: "";
  background: linear-gradient(90deg, rgb(255, 255, 255) 5%, rgba(0, 0, 0, 0) 30%, rgba(0, 0, 0, 0) 70%, rgb(255, 255, 255) 95%);
  z-index: 1;
  position: absolute;
  inset: 0;
  pointer-events: none;
}

.splide__track {
  border-radius: 20px;
  cursor: grab;
}

.splide__progress {
  box-shadow: inset 0px 2px 4px -1px rgba(0, 0, 0, 0.4);
  background-color: rgb(253, 253, 253);
  border-radius: 20px;
  width: 40%;
  margin: 0 auto;
}

.splide__progress__bar {
  box-shadow: inset 0px 2px 4px -1px rgba(0, 0, 0, 0.4);
  background: linear-gradient(90deg, #00ffbd, #1337ff);
  background-size: 300% 100%;
  border-radius: 20px;
  height: 10px;
  transition: width 100ms ease;
  width: 0;
  margin-top: 1rem;
}

.animatebar {
  animation: AnimationName 20600ms infinite;
}

@keyframes AnimationName {
  0% {
    background-position: 100%;
  }
  96% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}
.splide__slide.is-active {
  box-shadow: var(--shadow-2);
  filter: none;
}

.splide__slide {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
  width: 80%;
  color: white;
  padding: 3rem;
  border-radius: 20px;
  max-inline-size: 100%;
  margin-bottom: 20px;
  box-shadow: none;
  filter: grayscale(60%) blur(2px);
  transition: box-shadow 1s, filter 0.5s;
}

.splide__slide h2 {
  position: relative;
  background: var(--gradient-11);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  transform-style: preserve-3d;
}

.splide__slide h2::before {
  transform: translateZ(-1px);
  position: absolute;
  left: -10px;
  top: 0;
  border-radius: var(--radius-blob-3);
  width: var(--size-fluid-6);
  height: var(--size-fluid-5);
  content: "";
  background: var(--gradient-22);
}

.splide__slide p {
  max-inline-size: 100%;
  position: relative;
}

.slide1 {
  background: radial-gradient(#1fe4f5, #3fbafe);
}

.slide2 {
  background: radial-gradient(#fbc1cc, #fa99b2);
}

.slide3 {
  background: radial-gradient(#76b2fe, #b69efe);
}

.slide4 {
  background: radial-gradient(#60efbc, #58d5c9);
}

.slide5 {
  background: radial-gradient(#f588d8, #c0a3e5);
}

.slide6 {
  background: radial-gradient(#ff82a1, #fbc1cc);
}

.slide7 {
  background: radial-gradient(#ff1fda, #fa99b2);
}

.splide__pagination__page {
  box-shadow: inset 0px 2px 4px -1px rgba(0, 0, 0, 0.4);
  background-color: rgb(253, 253, 253);
}

.splide__pagination__page.is-active {
  background-color: #5359e0;
}

.splide__pagination__page:hover {
  background-color: #1fe4f5;
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .splide {
    width: 115vw;
  }
  .splide__slide {
    width: 110%;
    padding: 1.5rem;
    filter: none;
    transition: box-shadow 0.4s;
  }
  .splide__slide h2::before {
    width: 70px;
    height: 50px;
  }
  .splide .splide__list {
    transition: none;
  }
  .splide::before {
    content: "";
    background: none;
  }
  .splide__slide {
    justify-content: center;
  }
  .splide__progress {
    border-radius: 10px;
    width: 55%;
    margin: 20px auto;
  }
}